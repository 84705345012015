// import Logo from "../../assets/images/Logo.svg";
import BackgroundImage from "../../assets/images/BackgroundImage.svg";
import LogoSider from "../../assets/images/Logoooo.png";
import UsersIcon from "../../assets/images/usersIcon.svg";
import TravelPlanIcon from "../../assets/images/travelPlanIcon.svg";
import OrdersIcon from "../../assets/images/ordersIcon.svg";
import Users from "../../assets/images/Users.svg";
import MsgsIcon from "../../assets/images/msgsIcon.svg";
import CalenderFilterIcon from "../../assets/images/userCalenderFilter.svg";
import TotalUsersIcon from "../../assets/images/totaluserpic.svg";
import ActiveUsersIcon from "../../assets/images/activeuserpic.svg";
import BannedDeletedUserIcon from "../../assets/images/banneddeleteduserpic.svg";
import DashboardIcon from "../../assets/images/dashboardicon.svg";
import GraphCalenderIcon from "../../assets/images/GraphCalenderIcon.svg";
import DashboardIconClicked from "../../assets/images/dashboardIconAfterClick.svg";
import UserIconClicked from "../../assets/images/userIconClicked.svg";
import OrderIconClicked from "../../assets/images/orderIconClicked.svg";

// ===========================Play Pound Profit Images============================

import SignInPhone from "../../assets/images/playpoundprofit/signInPhone.png";
import Logo from "../../assets/images/playpoundprofit/logo.svg";
import Check from "../../assets/images/playpoundprofit/Noti.png";
import Waiting from "../../assets/images/playpoundprofit/Waiting.png";
import Dashboard from "../../assets/images/playpoundprofit/Dicon.png";
import DashboardClicked from "../../assets/images/playpoundprofit/DiconClicked.png";

import User from "../../assets/images/playpoundprofit/users.png";
import UserClciked from "../../assets/images/playpoundprofit/usersClicked.png";

import Game from "../../assets/images/playpoundprofit/GameIcon.png";
import GameClicked from "../../assets/images/playpoundprofit/GameIconClicked.png";

import Trophy from "../../assets/images/playpoundprofit/trophy.png";
import TrophyClicked from "../../assets/images/playpoundprofit/trophyClicked.png";

import Winner from "../../assets/images/playpoundprofit/trophy.png";
import WinnerClicked from "../../assets/images/playpoundprofit/trophyClicked.png";

import Message from "../../assets/images/playpoundprofit/chat.png";
import MessageClicked from "../../assets/images/playpoundprofit/chatClicked.png";
import Transactions from "../../assets/images/playpoundprofit/listBox.png";
import TransactionsClicked from "../../assets/images/playpoundprofit/listBoxClicked.png";

import DashboardOne from "../../assets/images/playpoundprofit/dashboard1.png";
import DashboardTwo from "../../assets/images/playpoundprofit/dashboard2.png";
import DashboardThree from "../../assets/images/playpoundprofit/dashboard3.png";
import MessageOne from "../../assets/images/playpoundprofit/message1.png";
import MessageTwo from "../../assets/images/playpoundprofit/message2.png";
import MessageThree from "../../assets/images/playpoundprofit/message3.png";

const Images = {
  // Logo,
  BackgroundImage,
  LogoSider,
  UsersIcon,
  TravelPlanIcon,
  OrdersIcon,
  MsgsIcon,
  CalenderFilterIcon,
  Users,
  TotalUsersIcon,
  ActiveUsersIcon,
  BannedDeletedUserIcon,
  DashboardIcon,
  GraphCalenderIcon,
  DashboardIconClicked,
  UserIconClicked,
  OrderIconClicked,

  // PPP Images Exported
  Dashboard,
  DashboardClicked,
  User,
  UserClciked,
  Game,
  GameClicked,
  Trophy,
  TrophyClicked,
  Winner,
  WinnerClicked,
  Message,
  MessageClicked,
  SignInPhone,
  Transactions,
  TransactionsClicked,
  Logo,
  Check,
  Waiting,
  DashboardOne,
  DashboardTwo,
  DashboardThree,
  MessageOne,
  MessageTwo,
  MessageThree

};

export default Images;
