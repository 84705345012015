import React, { useEffect, useState } from "react";
import { Layout } from "../../components";
import Header from "../../components/Layout/Header";
import { useDispatch, useSelector } from "react-redux";
import { UserAction } from "../../store/actions";
import { Row, Col, Table, Avatar, Switch, Pagination, Input } from "antd";
import { FaSearch } from "react-icons/fa";

const UserManagement = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");

  const dispatch = useDispatch();
  const data = useSelector((store) => {
    return store.UserReducer;
  });

  const usersCount = data?.userCount;

  useEffect(() => {
    const getUsers = {
      limit: 10,
      offSet: 0,
    };
    dispatch(UserAction.GetUsers(getUsers));
  }, []);

  const headerProps = {
    mainHeading: `User Management (${data?.userCount})`,
    subHeading: "",
  };

  const handle_payDone = (p) => {
    const limit = 10;
    let offSet = (p - 1) * limit;
    setCurrentPage(p);
    const getUsersByPage = {
      limit,
      offSet,
    };
    dispatch(UserAction.GetUsers(getUsersByPage));
  };
  const columns = [
    {
      title: "",
      dataIndex: "image",
      key: "image",
      // width: "4%",
      render: (i, b) => (
        <div>{<Avatar src={b?.imageUrl} shape="square" />}</div>
      ),
    },
    {
      title: "User Name",
      dataIndex: "fullName",
      key: "name",
      width: "13%",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, record) => {
        return <div>{record?.deletedAt == null ? record?.email : "-"}</div>;
      },
      // width: "5%",
    },
    {
      title: "No. of paid games played",
      dataIndex: "paidGamesPlayed",
      key: "paidGames",
      // width: "5%",
    },
    {
      title: "No. of free games played",
      dataIndex: "freeGamesPlayed",
      key: "freegames",
      // width: "5%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "10%",
      render: (e) => (
        <div>
          {e == 1 ? (
            <p className="tblActive">Active</p>
          ) : (
            <p className="tblIn-Active">In-Active</p>
          )}
        </div>
      ),

      // width: "5%",
    },
    {
      title: "Action",
      dataIndex: "status",
      key: "status",
      render: (isactive, user) => (
        <div
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          {
            <div>
              <Switch
                checked={isactive == 1 ? false : true}
                onChange={(e) => {
                  dispatch(
                    UserAction.UpdateUserStatus({
                      id: user?.id,
                      status: isactive == 1 ? 2 : 1,
                      limit: 10,
                      offSet: (currentPage - 1) * 10,
                    })
                  );
                  setSearch("");
                }}
              />
            </div>
          }
        </div>
      ),
    },
  ];

  useEffect(() => {
    const getUsers = {
      limit: 10,
      offSet: 0,
      search,
    };
    dispatch(UserAction.GetUsers(getUsers));
  }, [search]);
  return (
    <Layout.DashboardHeader
      // click={this.handleClose}
      columnSpacing={5}
      customizeStyle={{
        display: "flex",
        padding: 25,
        backgroundColor: "#fffcfc",
        marginLeft: 2,
      }}
    >
      <Header headings={headerProps} />
      <Col xs={24} sm={24} md={24} lg={10}>
        <Input
          type="text"
          placeholder="Search here"
          className="searchBar"
          value={search}
          size="large"
          suffix={<FaSearch style={{ color: "#54ac3e" }} />}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Col>
      <div className="tbl_game">
        <Row>
          <Col span={24}>
            <Table
              dataSource={data?.users}
              columns={columns}
              pagination={false}
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
        <Row>
          <Col
            span={24}
            style={{ width: "100%", marginTop: "3rem", marginBottom: "3rem" }}
          >
            {/* <Row style={{ width: "100%", marginTop: "3rem" }}>
            <Col span={24}> */}
            <Pagination
              responsive="true"
              showLessItems="true"
              defaultCurrent={1}
              current={currentPage}
              total={usersCount ? usersCount : 0}
              onChange={(p) => {
                handle_payDone(p);
              }}
            />
            {/* </Col>
          </Row> */}
          </Col>
        </Row>
      </div>
    </Layout.DashboardHeader>
  );
};

export default UserManagement;
