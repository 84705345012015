import {
  PAY_IN_DETAILS_REQUEST,
  PAY_IN_DETAILS_SUCCESS,
  PAY_IN_DETAILS_FAILURE,
  PAY_OUT_DETAILS_REQUEST,
  PAY_OUT_DETAILS_SUCCESS,
  PAY_OUT_DETAILS_FAILURE,
} from "../constants";

const initialState = {
  payInDetailsData: [],
  payInDetailsLoading: false,
  payOutDetailsLoading: false,
  payOutDetailsData: [],
};

export default function TransactionReducer(state = initialState, action) {
  switch (action.type) {
    case PAY_IN_DETAILS_REQUEST:
      state = {
        ...state,
        payInDetailsLoading: true,
      };
      break;
    case PAY_IN_DETAILS_SUCCESS:
      state = {
        ...state,
        payInDetailsLoading: false,
        payInDetailsData: action.payload,
      };
      break;
    case PAY_IN_DETAILS_FAILURE:
      state = {
        ...state,
        payInDetailsLoading: false,
      };
      break;

    case PAY_OUT_DETAILS_REQUEST:
      state = {
        ...state,
        payOutDetailsLoading: true,
      };
      break;
    case PAY_OUT_DETAILS_SUCCESS:
      state = {
        ...state,
        payOutDetailsLoading: false,
        payOutDetailsData: action.payload,
      };
      break;
    case PAY_OUT_DETAILS_FAILURE:
      state = {
        ...state,
        payOutDetailsLoading: false,
      };
      break;
    default:
      break;
  }

  return state;
}
