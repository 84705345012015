import {
  PAY_IN_DETAILS_REQUEST,
  PAY_IN_DETAILS_SUCCESS,
  PAY_IN_DETAILS_FAILURE,
  PAY_OUT_DETAILS_REQUEST,
  PAY_OUT_DETAILS_SUCCESS,
  PAY_OUT_DETAILS_FAILURE,
} from "../constants";

export default class TransactionActions {
  static PayInDetailsRequest(params) {
    return {
      type: PAY_IN_DETAILS_REQUEST,
      params,
    };
  }
  static PayInDetailsSuccess(payload) {
    return {
      type: PAY_IN_DETAILS_SUCCESS,
      payload,
    };
  }
  static PayInDetailsFailure(error) {
    return {
      type: PAY_IN_DETAILS_FAILURE,
      error,
    };
  }

  static PayOutDetailsRequest(params) {
    return {
      type: PAY_OUT_DETAILS_REQUEST,
      params,
    };
  }
  static PayOutDetailsSuccess(payload) {
    return {
      type: PAY_OUT_DETAILS_SUCCESS,
      payload,
    };
  }
  static PayOutDetailsFailure(error) {
    return {
      type: PAY_OUT_DETAILS_FAILURE,
      error,
    };
  }
}
