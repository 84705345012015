import {
  GETUSERS,
  GETUSERS_SUCCESS,
  GETUSERS_FAILURE,
  UPDATEUSERSTATUS,
  UPDATEUSERSTATUS_SUCCESS,
  UPDATEUSERSTATUS_FAILURE,
} from "../constants";

export default class UserAction {
  static GetUsers(payload) {
    return {
      type: GETUSERS,
      payload,
    };
  }
  static GetUsersSuccess(payload) {
    return {
      type: GETUSERS_SUCCESS,
      payload,
    };
  }
  static GetUsersFailure() {
    return {
      type: GETUSERS_FAILURE,
    };
  }

  static UpdateUserStatus(payload) {
    return {
      type: UPDATEUSERSTATUS,
      payload,
    };
  }
  static UpdateUserStatusSuccess() {
    return {
      type: UPDATEUSERSTATUS_SUCCESS,
    };
  }
  static UpdateUserStatusFailure() {
    return {
      type: UPDATEUSERSTATUS_FAILURE,
    };
  }
}
