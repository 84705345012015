import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import {
  Dashboard,
  UsersDetail,
  // ------------------
  UserManagement,
  WinnerManagement,
  Games,
  Transactions,
  Messages
} from "../../containers";
class AppRoutes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="App">
        <Switch>
          <Route exact path="/" component={Dashboard} />
          {/* ----------------Play pound profit--------------------- */}
          <Route exact path="/winner-management" component={WinnerManagement} />
          <Route exact path="/user-management" component={UserManagement} />
          <Route exact path="/games" component={Games} />
          <Route exact path="/transaction" component={Transactions} />
          <Route exact path="/messages" component={Messages} />

          <Route exact path="*" render={() => <Redirect to="/" />} />
        </Switch>
      </div>
    );
  }
}

export default connect((state) => ({ storeState: state }))(AppRoutes);
