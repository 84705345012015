import {
  GET_GAMES,
  GET_GAMES_SUCCESS,
  GET_GAMES_FAILURE,
  GETFILTERGAMES,
  GETFILTERGAMES_SUCCESS,
} from "../constants";

export default class GamesActions {
  static GetGames() {
    return {
      type: GET_GAMES,
    };
  }
  static GetGames_Success(payload) {
    return {
      type: GET_GAMES_SUCCESS,
      payload,
    };
  }

  static GetFilterGames() {
    return {
      type: GETFILTERGAMES,
    };
  }
  static GetFilter_Success(payload) {
    return {
      type: GETFILTERGAMES_SUCCESS,
      payload,
    };
  }
}
