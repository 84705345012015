import { LoadingButton } from '@mui/lab'

const variants = {
    primary: {
        background: 'linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(30, 143, 62, 1) 0%, rgba(95, 178, 63, 1) 100%)',
    },
    secondary: {
        background: '#435179',
        '&:hover': {
            background: '#435179',
        },
    },
}

export const CustomButton = ({ children, sx, variant = "primary", ...rest }) => {
    return <LoadingButton
        sx={{
            border: 'none',
            borderRadius: '10px',
            color: '#ffffff',
            fontWeight: 'semibold',
            cursor: 'pointer',
            letterSpacing: '1px',
            textTransform: 'capitalize',
            px: 3,
            py: 1.5,
            '.MuiCircularProgress-svg': {
                color: '#ffffff',
            },
            whiteSpace: 'nowrap',
            ...variants[variant],
            ...sx,
        }}
        {...rest}
    >
        {children}
    </LoadingButton>
}