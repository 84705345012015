import React from 'react';
import {Colors} from "../../config"
import styled from "styled-components";

const StatBar = styled.div`
  background-color: ${props => props.status == "Active" ? "blue" : "#f8604e"};
  color: ${Colors.White};
  border-radius: 30px;
  width: 7rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const StatusBar = (props) => {
  const capitalizeChar = (str) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  }
  return <StatBar  {...props}>
    {capitalizeChar(props.status)}
  </StatBar>;
}

export default React.memo(StatusBar)
