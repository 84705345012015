import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
    SignIn,
    ForgetPassword,
    VerifyCode,
    UpdatePassword
} from "../../containers";
import AppRoutes from "./AppRoutes";

class AuthRoutes extends React.Component {
    render() {
        return (
            <>
                <Switch>
                    {!this.props.user ? (
                        <Switch>
                            <Route exact path="/login" component={SignIn} />
                            <Route exact path="/forget-password" component={ForgetPassword} />
                            <Route exact path="/verify-code" component={VerifyCode} />
                            <Route exact path="/update-password" component={UpdatePassword} />
                            <Route path="*" render={() => <Redirect to="/login" />} />
                        </Switch>
                    ) : (
                        <Switch>
                            <Route path="/" component={AppRoutes} />
                        </Switch>
                    )}
                </Switch>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.AuthReducer.user,
    }
}

export default withRouter(
    connect(mapStateToProps)(AuthRoutes)
)