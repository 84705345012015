import React from "react";
import { Row, Col, Card, Avatar, Menu, Dropdown } from "antd";
import { RiShutDownLine } from "react-icons/ri";
import { Colors } from "../../config";
import { useDispatch } from "react-redux";
import { AuthAction } from "../../store/actions";
import { AiOutlineUser } from "react-icons/ai";

const Header = (props) => {
  const dispatch = useDispatch();
  const menu = (
    <Menu>
      <Menu.Item>
        <div
          style={{ marginRight: "54px", cursor: "pointer" }}
          onClick={() => dispatch(AuthAction.Logout())}
        >
          <RiShutDownLine size={16} style={{ color: Colors.Danger }} /> Logout
        </div>
      </Menu.Item>
    </Menu>
  );

  const { mainHeading, subHeading } = props.headings;
  const image = JSON.parse(localStorage.getItem("PPP_Auth"))?.user
    ?.imageThumbUrl;

  return (
    <Row
      justify="center"
      style={{ width: "100%", marginTop: "3rem", marginBottom: "2rem" }}
    >
      <Col span={24}>
        <Row justify="space-between" style={{ alignItems: "center" }}>
          <Col xs={24} sm={24} md={10} lg={11}>
            <Row>
              <Col span={24}>
                <h2 className="header_MainHeading">
                  {/* {checkUrl.mainHeading} */}
                  {mainHeading}
                </h2>
              </Col>

              {subHeading ? (
                <Col span={24} className="header_Subheading">
                  <h4 style={{ textTransform: "capitalize" }}>{subHeading}</h4>
                </Col>
              ) : (
                ""
              )}
            </Row>
          </Col>

          <Col xs={24} sm={24} md={10} lg={11} style={{ padding: "5px" }}>
            <Row justify="end">
              <Col>
                <Dropdown overlay={menu}>
                  <Card className="headerCard" style={{ width: 300 }}>
                    <Row align="middle">
                      <Col span={6}>
                        <Avatar
                          shape="square"
                          icon={!image ? <AiOutlineUser /> : null}
                        />
                      </Col>
                      <Col span={14}>
                        <Row>
                          <Col span={24}>
                            <h4 className="para">James Rowley</h4>
                          </Col>
                          <Col span={24}>
                            <h5 className="para">Admin</h5>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Dropdown>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Header;
