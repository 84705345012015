import { WinnersAction } from "../actions";
import { ApiCaller, Utils } from "../../config";
import { put } from "redux-saga/effects";
import { WINNER_ENDPOINT, WINNER_UPDATE_ENDPOINT } from "../../config/variables";

export default class DashboardMiddleware {
  static *GetWinner({ payload }) {
    const { gameId, limit, offSet, status, search } = payload;

    try {
      let res = yield ApiCaller.Get(
        `${WINNER_ENDPOINT}?limit=${limit}&offset=${offSet}&paymentStatus=${status}&gameId=${gameId ? gameId : ""
        }&search=${search ? search : ""}`
      );
      if (res.status == 200) {
        const objWinnerPayDone = {
          count: res.data.data.count,
          winners: res.data.data.winners,
        };
        yield put(WinnersAction.GetWinners_Success(objWinnerPayDone));
      }
    } catch (err) { }
  }

  static *MarkWinnerPaid(params) {
    const payload = params?.payload;
    try {
      let res = yield ApiCaller.Post(`${WINNER_UPDATE_ENDPOINT}/paid`, payload);
      if (res.status === 200) {
        Utils.showSnackBar({ message: res?.data?.message ?? 'Winner marked as paid' });
        yield put(WinnersAction.MarkWinnerPaidSuccess());
        yield put(WinnersAction.GetWinners({})); // (line in progress)=================================================================
      } else {
        yield put(WinnersAction.MarkWinnerPaidFailure());
        //   Info("error", res.data.error.message);
        Utils.showSnackBar({
          message: res.data.error.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(WinnersAction.MarkWinnerPaidFailure());
      Utils.showSnackBar({
        message: err.message, // (line in progress)
        severity: "error",
      });
    }
  }
}
