import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect } from 'react';

const Basic = ({ onClose, dialogContent, sx, open, maxWidth, dialogTitle, LeftButtonText, RightButtonText, onClickLeft, onClickRight, ...props }) => {
  return (
    <div>

      <Dialog
        open={open}
        sx={sx} //styling
        maxWidth={maxWidth}

        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dialogTitle}

        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogContent}

          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClickLeft}>{LeftButtonText}</Button>
          <Button autoFocus onClick={onClickRight}>
            {RightButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default Basic;
