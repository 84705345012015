import { Box, ButtonBase } from "@mui/material";
import { FaCopy } from 'react-icons/fa'
import copy from 'copy-to-clipboard';
import { Utils } from "../../config";

export function CopyToCB({ children, sx, content }) {
    const handleCopy = () => {
        copy(content);
        Utils.showSnackBar({ message: 'Copied to Clipboard!' });
    }

    return <Box component='span' sx={{ position: "relative", overflow: "hidden", ...sx }}>
        {children}
        <ButtonBase component="span" sx={{ position: "absolute", px: 1, top: 0, right: 0, bottom: 0, bgcolor: "#fff" }} onClick={handleCopy}><FaCopy /></ButtonBase>
    </Box>
}