import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Utils } from "../../config";
import { AuthAction } from "../../store/actions";
import { Images, Colors } from "../../config";
import {
  RiMailFill,
  RiEyeFill,
  RiEyeCloseLine,
  RiCheckboxCircleFill,
} from "react-icons/ri";
import { AiFillLock } from "react-icons/ai";
import { Row, Col, Input } from "antd";

const UpdatePassword = (props) => {
  const email = props?.location?.state;
  const [showPass, setShowPass] = useState(false);
  const [showConPass, setShowConPass] = useState(false);

  const [password, setPassword] = useState("");
  const [passErrMes, setPassErrMes] = useState("");

  const [confrimPassword, setConfirmPassword] = useState("");
  const [confrimPassErrMes, setConfrimPassErrMes] = useState("");

  const [validPass, setValidPass] = useState(true);
  const [validConPass, setValidConPass] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();

  const changePass = () => {
    if (!password || !validPass || !confrimPassword || !validConPass) {
      if (!password) {
        setPassErrMes("Required fields can not be left empty.");
        setValidPass(false);
      }
      if (!validPass) {
        setPassErrMes(
          "Password length must be atleast 8 char and must include an upper case and lower case char"
        );
      }
      if (!confrimPassword) {
        setConfrimPassErrMes("Required fields can not be left empty.");
        setValidConPass(false);
      }
      if (!validConPass) {
        setConfrimPassErrMes(
          "Password length must be atleast 8 char and must include an upper case and lower case char"
        );
      }
    }
    else if (password !== confrimPassword) {
      setConfrimPassErrMes("Password does not match");
    } 
    else if (!confrimPassword) {
      setConfrimPassErrMes("Required fields can not be left empty.");
    } 
    else {
      const objUpdatePass = {
        password,
        email,
      };
      dispatch(
        AuthAction.ResetPassword(objUpdatePass, () => history.replace("/login"))
      );
    }
    //  else
    //     SignIn({ current_time, email, password }, () => history.push('/'))
  };

  const validatePass = (password) => {
    // let validPass = password.length > 5;
    let validPass = Utils.passwordRegex.test(String(password));
    setPassword(password);
    setValidPass(validPass);
    setPassErrMes("");
  };
  const validateConPass = (password) => {
    // let validPass = password.length > 5;
    let validPass = Utils.passwordRegex.test(String(password));
    setConfirmPassword(password);
    setValidConPass(validPass);
    setConfrimPassErrMes("");
  };
  return (
    <Row justify="center">
      <Col className="imgColumn" xs={0} sm={0} md={12} lg={12}>
        <Row justify="center" style={{ padding: "3rem 0rem" }}>
          <Col span={18}>
            <Row justify="center">
              <Col>
                <h1 className="headingOne">Get Better at Gaming!</h1>
              </Col>
              <Col>
                <h1 className="regularHead">Dedicate Time to Playing Games.</h1>
              </Col>
              <Col>
                <img src={Images.SignInPhone} className="Box1_PhoneImg" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Col span={12} className="formColumn" xs={24} sm={24} md={12} lg={12}>
        <Row justify="center">
          <Col span={18}>
            <Row justify="left" style={{ padding: "3rem 0rem" }}>
              <Col>
                <img src={Images.Logo} />
              </Col>

              <Col>
                <h1 className="headingOne">Update Password.</h1>
              </Col>

              <Col span={24}>
                <Row>
                  <Col className="colPass" span={24}>
                    <div>
                      <p className="label">New Password</p>
                    </div>
                    <Input
                      type={!showPass ? "password" : "text"}
                      placeholder="Password"
                      name="password"
                      value={password}
                      className="inputStyles"
                      prefix={<AiFillLock />}
                      suffix={
                        !showPass ? (
                          <RiEyeCloseLine
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowPass(!showPass)}
                          />
                        ) : (
                          <RiEyeFill
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowPass(!showPass)}
                          />
                        )
                      }
                      onChange={(e) => validatePass(e.target.value)}
                    />
                    <span style={{ color: Colors.Danger }}> {passErrMes} </span>
                  </Col>

                  {/* ==================================== */}

                  <Col className="colPass" span={24}>
                    <div>
                      <p className="label">Confirm Password</p>
                    </div>
                    <Input
                      type={!showConPass ? "password" : "text"}
                      placeholder="Password"
                      name="password"
                      value={confrimPassword}
                      className="inputStyles"
                      prefix={<AiFillLock />}
                      suffix={
                        !showConPass ? (
                          <RiEyeCloseLine
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowConPass(!showConPass)}
                          />
                        ) : (
                          <RiEyeFill
                            style={{ cursor: "pointer" }}
                            onClick={() => setShowConPass(!showConPass)}
                          />
                        )
                      }
                      onChange={(e) => validateConPass(e.target.value)}
                    />
                    <span style={{ color: Colors.Danger }}>
                      {" "}
                      {confrimPassErrMes}{" "}
                    </span>
                  </Col>

                  <Col span={24} style={{ marginTop: "2rem" }}>
                    <button
                      className="btn btnLogin"
                      size="large"
                      onClick={() => changePass()}
                    >
                      SUBMIT
                    </button>
                  </Col>
                  <Col span={24} style={{ marginTop: "1rem" }}>
                    <p
                      className="txt_forgotPass"
                      onClick={() => history.push("/forget-password")}
                    >
                      Forgot your password?
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default UpdatePassword;
