import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Avatar, Divider, Stack, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { WinnersAction } from '../../../store/actions';
import { CustomButton } from '../../../components/ui/button/CustomButton';
import { CopyToCB } from '../../../components/atoms/CopyToCB';

const WinnerDetailsModal = ({ record }) => {
    const { isMarkPaidOpen, isLoading } = useSelector(state => state.WinnerReducer);
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(WinnersAction.CloseMarkPaidModal());
    };

    const markAsPaid = (prizePoolId, userId) => {
        if (!record) return
        dispatch(
            WinnersAction.MarkWinnerPaid({
                prizePoolId: record?.id,
                userId: record?.user?.id,
            })
        );
    }

    const information = {
        // "Bank Name": record?.user?.userBankDetail?.bankName ?? record?.user?.userBankDetail?.bank?.title,
        "Bank Name": record?.user?.userBankDetail?.bankName == "" ? record?.user?.userBankDetail?.bank?.title
            : record?.user?.userBankDetail?.bankName ? record?.user?.userBankDetail?.bankName
                : record?.user?.userBankDetail?.bank?.title,
        "Account Title": record?.user?.userBankDetail?.accountTitle,
        "Account Number": () => (<CopyToCB sx={{ flex: 1.5 }} content={record?.user?.userBankDetail?.accountNumber}>
            <Typography sx={{ fontSize: { sm: '16px', xs: '14px' }, color: "#000000", fontWeight: 600 }}>
                {record?.user?.userBankDetail?.accountNumber}
            </Typography>
        </CopyToCB>),
        "Requested Amount": "£50",
    }

    return (
        <Dialog
            open={isMarkPaidOpen && record}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                '.MuiPaper-root': {
                    borderRadius: '10px',
                    width: "min(100%, 500px)",
                    margin: '1rem',
                    py: 2
                }
            }}
        >
            <DialogTitle id="alert-dialog-title">
                <Stack alignItems="center" spacing={2}>
                    <Avatar alt="Remy Sharp" sx={{ borderRadius: '8px', width: 100, height: 100 }} src={record?.user?.imageUrl} />
                    <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>{record?.user?.fullName}</Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack alignItems="center" spacing={4} py={2}>
                    <Stack spacing={1.5} sx={{ width: "100%" }}>
                        {Object.entries(information).map(([key, value], idx) => {
                            const isLast = idx === (Object.entries(information).length - 1)
                            return <>
                                <Divider />
                                <Stack key={key} sx={{ flexDirection: { sm: "row" } }}>
                                    <Typography sx={{ color: "#435179", fontWeight: 600, fontSize: "16px", flex: 1 }}>{key}</Typography>
                                    {key === "Account Number" ?
                                        value() :
                                        <Typography className="no-scrollbar" sx={{ fontSize: { sm: '16px', xs: '14px' }, color: isLast ? "#54AC3F" : "#000000", fontWeight: 600, flex: 1.5, overflow: 'auto' }}>{value}</Typography>
                                    }
                                </Stack>
                                {isLast && <Divider />}
                            </>
                        })}
                    </Stack>

                </Stack>
            </DialogContent>
            <DialogActions
                component={Stack}
                direction="row"
                width="100%"
                sx={{ justifyContent: "center", pb: 2, gap: { sm: 1.25, xs: 1 } }}

            >
                <CustomButton
                    sx={{ flex: 1, maxWidth: "150px" }}
                    loading={isLoading}
                    onClick={markAsPaid}
                    disabled={isLoading}
                >
                    Mark As Done
                </CustomButton>
                <CustomButton
                    sx={{ flex: 1, maxWidth: "150px" }}
                    variant="secondary"
                    onClick={onClose}
                >
                    Cancel
                </CustomButton>
            </DialogActions>
        </Dialog>
    );
}

export default WinnerDetailsModal;
