import "./App.css";
import React, { Component } from "react";
import { Loader, Popup } from "./components";
import { Colors, Utils } from "./config";
import AuthRoutes from "./config/routes/AuthRoutes";
import { connect } from "react-redux";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom.min";
const { saveSnackbarRef } = Utils;

class App extends Component {
  constructor() {
    super();
    this.state = {
      loader: false,
    };
  }

  render() {
    if (this.props.loader) {
      document.body.style.overflow = "hidden";
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      document.body.style.overflow = "auto";
    }

    return (
      <>
        <BrowserRouter basename="/admin-panel">
          <AuthRoutes />
        </BrowserRouter>
        <Popup.Snackbar ref={(ref) => saveSnackbarRef(ref)} />

        {this.props.loader ? (
          <div
            style={{
              display: "flex",
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: Colors.BlackOpacity(0.7),
            }}
          >
            <Loader.Circular sx={{ color: Colors.WhiteOpacity(0.7) }} />
          </div>
        ) : null}
      </>
    );
  }
}

function mapStateToProps(state) {

  return {
    loader:
      state.AuthReducer.loader ||
      state.GamesReducer.isLoading ||
      // state.WinnerReducer.isLoading ||
      // state.UserReducer.isLoading ||
      // state.DashboardReducer.isLoading ||
      state.MessageReducer.isLoading ||
      state.TransactionReducer.isLoading,
  };
}
function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
