import {
    GET_GAMES,
    GET_GAMES_SUCCESS,
    GETFILTERGAMES,
    GETFILTERGAMES_SUCCESS
} from '../constants'

const initialState = {
    isLoading: false,
    gameData: [],
    allGames: []
}

export default function DashboardReducer(state = initialState, action) {
    switch (action.type) {
        case GET_GAMES:
            state = {
                ...state,
                isLoading: true
            }
            break

        case GET_GAMES_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                gameData: action.payload
            }
            break

        case GETFILTERGAMES:
            state = {
                ...state,
                isLoading: false
            }
            break

        case GETFILTERGAMES_SUCCESS:
            state = {
                ...state,
                isLoading: false,
                allGames: action.payload

            }
            break


        default:
            break;
    }
    return state;
}