import {
  AuthMiddleware,
  UserMiddleware,
  DashboardMiddleware,
  OrderMiddleware,
  GamesMiddleware,
  WinnerMiddleware,
  NotificationMiddleware,
  MessageMiddleware,
  TransactionMiddleware,
} from "../middlewares";
import { takeLatest, all, takeEvery } from "redux-saga/effects";
import {
  ADD_POST,
  GET_POSTS,
  LOGOUT,
  FORGETPASSWORD,
  RESETPASSWORD,
  TRAVELPLAN,
  BUYERPLAN,
  VERIFYCODE,
  GETUSERS,
  ANDROID_GRAPH,
  GETUSERSCARDCOUNT,
  USER_GRAPH,
  UPDATEUSERSTATUS,
  GETORDERSCARDCOUNT,
  // --------------------------------------
  SIGNIN,
  GET_GAMES,
  GETFILTERGAMES,
  GET_WINNERS,
  SEND_NOTIFICATION,
  USER_STATS,
  FREE_PAID_GAMES,
  TOTAL_GAMES,
  SIGNUP_STATS,
  MESSAGE,
  REPLY_MESSAGE,
  DELETE_MESSAGE,
  PAY_IN_DETAILS_REQUEST,
  PAY_OUT_DETAILS_REQUEST,
  TRANSACTION,
  MARK_WINNER_PAID,
} from "../constants";

export function* Sagas() {
  yield all([
    yield takeLatest(SIGNIN, AuthMiddleware.SignIn),
    // yield takeLatest(ADD_POST, AuthMiddleware.AddPost),
    // yield takeLatest(GET_POSTS, AuthMiddleware.GetPosts),
    yield takeLatest(LOGOUT, AuthMiddleware.Logout),
    yield takeLatest(FORGETPASSWORD, AuthMiddleware.ForgetPassword),
    yield takeLatest(RESETPASSWORD, AuthMiddleware.ResetPassword),
    yield takeLatest(VERIFYCODE, AuthMiddleware.VerifyCode),
    yield takeLatest(GETUSERS, UserMiddleware.GetUsers),
    // yield takeEvery(GETUSERSCARDCOUNT, DashboardMiddleware.GetUserCardCount),
    // yield takeLatest(USER_GRAPH, DashboardMiddleware.UserGraph),
    // yield takeLatest(ANDROID_GRAPH, DashboardMiddleware.AndroidGraph),
    yield takeLatest(UPDATEUSERSTATUS, UserMiddleware.UpdateUserStatus),
    // yield takeLatest(TRAVELPLAN, UserMiddleware.TravelUser),
    // yield takeLatest(BUYERPLAN, UserMiddleware.BuyerUser),
    // yield takeLatest(GETORDERSCARDCOUNT, OrderMiddleware.GetOrdersCount)
    
    //============================= PPP =======================================
    
    yield takeLatest(GET_GAMES, GamesMiddleware.GetGames),
    yield takeLatest(GETFILTERGAMES, GamesMiddleware.GetFilterdGames),
    yield takeLatest(GET_WINNERS, WinnerMiddleware.GetWinner),
    yield takeLatest(MARK_WINNER_PAID, WinnerMiddleware.MarkWinnerPaid), // WORKING ON THIS RIGHT NOW
    // yield takeLatest(GETWINNERS_PAY_MISSED, WinnerMiddleware.GetWinner_PaymentMissed),
    // yield takeLatest(GETWINNERS_PAY_PENDING, WinnerMiddleware.GetWinner_PaymentPending),
    yield takeLatest(
      SEND_NOTIFICATION,
      NotificationMiddleware.Send_Notification
    ),
    yield takeLatest(USER_STATS, DashboardMiddleware.GetUserStats),
    yield takeLatest(FREE_PAID_GAMES, DashboardMiddleware.GetFreeAndPaidGames),
    yield takeLatest(TOTAL_GAMES, DashboardMiddleware.GetTotalGamesPLayed),
    yield takeLatest(SIGNUP_STATS, DashboardMiddleware.GetSignUp_Stats),
    yield takeLatest(MESSAGE, MessageMiddleware.GetMessages),
    yield takeLatest(REPLY_MESSAGE, MessageMiddleware.SendMessage),
    yield takeLatest(DELETE_MESSAGE, MessageMiddleware.DeleteMessage),
    yield takeLatest(
      PAY_IN_DETAILS_REQUEST,
      TransactionMiddleware.PayInDetails
    ),
    yield takeLatest(
      PAY_OUT_DETAILS_REQUEST,
      TransactionMiddleware.PayOutDetails
    ),
  ]);
}
