import { CLOSE_MARK_PAID_MODAL, GET_WINNERS, GET_WINNERS_SUCCESS, MARK_WINNER_PAID, MARK_WINNER_PAID_FAILURE, MARK_WINNER_PAID_SUCCESS, OPEN_MARK_PAID_MODAL } from "../constants";

export default class WinnersAction {
  static GetWinners(payload) {
    return {
      type: GET_WINNERS,
      payload,
    };
  }
  static GetWinners_Success(payload) {
    return {
      type: GET_WINNERS_SUCCESS,
      payload,
    };
  }
  static OpenMarkPaidModal() {
    return {
      type: OPEN_MARK_PAID_MODAL,
    };
  }
  static CloseMarkPaidModal() {
    return {
      type: CLOSE_MARK_PAID_MODAL,
    };
  }
  static MarkWinnerPaid(payload) {
    return {
      type: MARK_WINNER_PAID,
      payload,
    };
  }
  static MarkWinnerPaidSuccess() {
    return {
      type: MARK_WINNER_PAID_SUCCESS,
    };
  }
  static MarkWinnerPaidFailure() {
    return {
      type: MARK_WINNER_PAID_FAILURE,
    };
  }
}
