import { TransactionActions } from "../actions";
import { ApiCaller } from "../../config";
import { put, delay } from "redux-saga/effects";
import { Utils } from "../../config";

import {
  PAY_IN_DETAILS_ENDPOINT,
  PAY_OUT_DETAILS_ENDPOINT,
} from "../../config/variables";

export default class TransectionMiddleware {
  static *PayInDetails(action) {
    const { params } = action;
    try {
      let res = yield ApiCaller.Get(
        `${PAY_IN_DETAILS_ENDPOINT}?startDate=${
          params?.startDate || ""
        }&endDate=${params?.endDate || ""}`
      );

      if (res.status == 200) {
        yield put(TransactionActions.PayInDetailsSuccess(res.data.data));
      } else {
        yield put(TransactionActions.PayInDetailsFailure());
        Utils.showSnackBar({
          message: res.error.message,
          severity: "error",
        });
      }
    } catch (err) {
      //   yield put(OrderAction.GetOrdersCardCountFailure());
    }
  }

  static *PayOutDetails(action) {
    const { params } = action;

    try {
      let res = yield ApiCaller.Get(
        `${PAY_OUT_DETAILS_ENDPOINT}?startDate=${
          params?.startDate || ""
        }&endDate=${params?.endDate || ""}&gamerId=${params?.gameId || ""}`
      );

      if (res.status == 200) {
        yield put(TransactionActions.PayOutDetailsSuccess(res.data.data));
      } else {
        yield put(TransactionActions.PayOutDetailsFailure());
        Utils.showSnackBar({
          message: res.error.message,
          severity: "error",
        });
      }
    } catch (err) {
      //   yield put(OrderAction.GetOrdersCardCountFailure());
    }
  }
}
