import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Fonts, Utils } from "../../config";
import { AuthAction } from "../../store/actions";
import { Images, Colors } from "../../config";
import { RiMailFill, RiCheckboxCircleFill } from "react-icons/ri";
import { Row, Col, Input } from "antd";
// import { ForgetPassword } from ".";

const ForgetPassword = () => {
  const [password, setPassword] = useState("");

  const [email, setEmail] = useState("");
  const [emailErrMes, setEmailErrMes] = useState("");

  const [validEmail, setValidEmail] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();

  const forgetPass = () => {
    if (!email || !validEmail) {
      if (!email) {
        setEmailErrMes("Required fields can not be left empty.");
        setValidEmail(false);
      }
      if (!validEmail) {
        setEmailErrMes("Please enter a valid email address.");
      }
    } else {
      localStorage.setItem("email", email);
      dispatch(
        AuthAction.ForgetPassword(email, () =>
          history.replace("/verify-code", email)
        )
      );
    }
    //  else
    //     SignIn({ current_time, email, password }, () => history.push('/'))
  };

  const validateEmail = (email) => {
    let validEmail = Utils.emailRegex.test(String(email).toLowerCase());
    setEmail(email);
    setValidEmail(validEmail);
    setEmailErrMes("");
  };

  return (
    <Row justify="center">
      <Col className="imgColumn" xs={0} sm={0} md={12} lg={12}>
        <Row justify="center" style={{ padding: "3rem 0rem" }}>
          <Col span={18}>
            <Row justify="center">
              <Col>
                <h1 className="headingOne">Get Better at Gaming!</h1>
              </Col>
              <Col>
                <h1 className="regularHead">Dedicate Time to Playing Games.</h1>
              </Col>
              <Col>
                <img src={Images.SignInPhone} className="Box1_PhoneImg" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Col span={12} className="formColumn" xs={24} sm={24} md={12} lg={12}>
        <Row justify="center">
          <Col span={18}>
            <Row justify="left" style={{ padding: "3rem 0rem" }}>
              <Col span={24}>
                <img src={Images.Logo} />
              </Col>

              <Col span={24}>
                <h1 className="headingOne">Forgot password</h1>
              </Col>

              <Col span={24}>
                <h2 className="regularHead">
                  Enter your Email address for the verification process,
                </h2>
              </Col>

              <Col span={24}>
                <Row>
                  <Col className="colEmail" span={24}>
                    <div>
                      <p className="label">Email Address</p>
                    </div>
                    <Input
                      type={"email"}
                      value={email}
                      placeholder={"Email"}
                      className="inputStyles"
                      onChange={(e) => validateEmail(e.target.value)}
                      prefix={<RiMailFill style={{ color: "#009743" }} />}
                      suffix={
                        <RiCheckboxCircleFill
                          style={{ color: email.length ? "#009743" : "red" }}
                        />
                      }
                      customStyle={{
                        backgroundColor: "white",
                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: Colors.Border,
                          },
                        "& .MuiOutlinedInput-root:hover": {
                          "& > fieldset": {
                            borderColor: Colors.Primary,
                          },
                        },
                      }}
                    />
                    <span style={{ color: Colors.Danger }}>
                      {" "}
                      {emailErrMes}{" "}
                    </span>
                  </Col>

                  <Col span={24} style={{ marginTop: "2rem" }}>
                    <button
                      className="btn btnLogin"
                      size="large"
                      onClick={() => forgetPass()}
                    >
                      SUBMIT
                    </button>
                  </Col>
                  <Col span={24} style={{ marginTop: "1rem" }}>
                    <p
                      className="txt_forgotPass"
                      onClick={() => history.push("/login")}
                    >
                      Back to Login
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ForgetPassword;
