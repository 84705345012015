import {
    SEND_NOTIFICATION
} from '../constants'


export default class NotificationAction {
    static SendNotification(payload) {
        return {
            type: SEND_NOTIFICATION,
            payload
        }
    }
}