import { AuthAction } from "../actions";
import { ApiCaller } from "../../config";
import { put } from "redux-saga/effects";
import { Utils } from "../../config";
import {
  LOGIN_ENDPOINT,
  LOGOUT_ENDPOINT,
  RESETPASSWORD_ENDPOINT,
  FORGETPASSWORD_ENDPOINT,
  VERIFYCODE_ENDPOINT,
} from "../../config/variables";
export default class AppMiddleware {
  static *SignIn(params) {
    const { email, password } = params.payload;
    const { cb } = params;
    const objSignIn = {
      email,
      password,
      platform: "web",
    };
    try {
      let res = yield ApiCaller.Post(LOGIN_ENDPOINT, objSignIn);
      if (res.status == 200) {
        const userObj = {
          user: res.data.data.user,
          token: res.data.data.x_auth_token,
        };
        localStorage.setItem("PPP_Auth", JSON.stringify(userObj));
        Utils.showSnackBar({
          message: res.data.data.message,
          severity: "success",
        });
        yield put(AuthAction.SignInSuccess(res.data.data.user));
        cb() && cb();
      } else {
        yield put(AuthAction.SignInFailure());
        Utils.showSnackBar({
          message: res.data.error.message,
          severity: "error",
        });
      }
    } catch (err) {
      // yield put(AuthAction.SignInFailure());
    }
  }

  static *Logout() {
    const userId = JSON.parse(localStorage.getItem("PPP_Auth"))?.user?.id;

    try {
      let res = yield ApiCaller.Post(`${LOGOUT_ENDPOINT}/${userId}`);

      if (res.status == 200) {
        yield put(AuthAction.LogoutSuccess());
        localStorage.removeItem("PPP_Auth");
        window.location.reload();
        Utils.showSnackBar({ message: "Successfully logout" });
      } else {
        yield put(AuthAction.LogoutFailure());
      }
    } catch (err) {
      yield put(AuthAction.LogoutFailure());
    }
  }

  static *VerifyCode(params) {
    const { payload, cb } = params;
    try {
      let res = yield ApiCaller.Post(VERIFYCODE_ENDPOINT, payload);

      if (res.status == 200) {
        Utils.showSnackBar({ message: res.data.data.message });
        cb() && cb();
        yield put(AuthAction.VerifyCodeSuccess());
      } else {
        yield put(AuthAction.VerifyCodeFailure());
        Utils.showSnackBar({
          message: res.data.error.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(AuthAction.VerifyCodeFailure());
    }
  }

  static *ForgetPassword(payload) {
    const { email, cb } = payload;

    try {
      let res = yield ApiCaller.Post(FORGETPASSWORD_ENDPOINT, { email });

      if (res.status == 200) {
        Utils.showSnackBar({ message: res.data.data.message });
        yield put(AuthAction.ForgetPasswordSuccess());
        cb() && cb();
      } else {
        yield put(AuthAction.ForgetPasswordFailure());
        Utils.showSnackBar({
          message: res.data.error.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(AuthAction.ForgetPasswordFailure());
    }
  }

  static *ResetPassword(params) {
    const { cb } = params;
    try {
      let res = yield ApiCaller.Post(RESETPASSWORD_ENDPOINT, params?.payload);
      if (res.status == 200) {
        Utils.showSnackBar({ message: res.data.data.message });
        cb() && cb();
        yield put(AuthAction.ResetPasswordSuccess());
      } else {
        yield put(AuthAction.ResetPasswordFailure());
        Utils.showSnackBar({
          message: res.data.error.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(AuthAction.ResetPasswordFailure());
    }
  }
}
