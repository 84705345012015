import {
  MESSAGE,
  MESSAGE_SUCCESS,
  MESSAGE_FAILURE,
  REPLY_MESSAGE,
  REPLY_MESSAGE_SUCCESS,
  REPLY_MESSAGE_FAILURE,
  DELETE_MESSAGE,
  DELETE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_FAILURE,
} from "../constants";

export default class MessagesActions {
  static getMessage(payload) {
    return {
      type: MESSAGE,
      payload,
    };
  }
  static Message_Success(payload) {
    return {
      type: MESSAGE_SUCCESS,
      payload,
    };
  }
  static Message_Failure(payload) {
    return {
      type: MESSAGE_FAILURE,
      payload,
    };
  }
  static replyMessage(payload) {
    return {
      type: REPLY_MESSAGE,
      payload,
    };
  }
  static replyMessage_Success(payload) {
    return {
      type: REPLY_MESSAGE_SUCCESS,
      payload,
    };
  }
  static replyMessage_Failure(payload) {
    return {
      type: REPLY_MESSAGE_FAILURE,
      payload,
    };
  }
  static deleteMessage(payload) {
    
    return {
      type: DELETE_MESSAGE,
      payload,
    };
  }
  static deleteMessage_Success(payload) {
    
    return {
      type: DELETE_MESSAGE_SUCCESS,
      payload,
    };
  }
  static deleteMessage_Failure(payload) {
    
    return {
      type: DELETE_MESSAGE_FAILURE,
      payload,
    };
  }
}
