import { MessagesActions } from "../actions";
import { ApiCaller } from "../../config";
import { put, delay } from "redux-saga/effects";
import { Utils } from "../../config";
import { MESSAGES_ENDPOINT } from "../../config/variables";

export default class NotificationMiddleware {
  static *GetMessages(action) {
    if (action?.payload?.id) {
      const { id, reply, ...rest } = action.payload;
      let qureyParams = new URLSearchParams(rest);
      let res = yield ApiCaller.Get(`${MESSAGES_ENDPOINT}?${qureyParams}`);

      if (res.status == 200) {
        const objMessages = {
          messages: res?.data?.data?.messages,
          pendingCount: res?.data?.data?.pendingCount,
          repliedCount: res?.data?.data?.repliedCount,
          totalCount: res?.data?.data?.totalCount,
        };
        yield put(MessagesActions.Message_Success(objMessages));
        //   Utils.showSnackBar({ message: res.data.data, severity: "success" });
      }
    } else {
      let qureyParams = new URLSearchParams(action.payload);

      let res = yield ApiCaller.Get(`${MESSAGES_ENDPOINT}?${qureyParams}`);

      if (res.status == 200) {
        const objMessages = {
          messages: res?.data?.data?.messages,
          pendingCount: res?.data?.data?.pendingCount,
          repliedCount: res?.data?.data?.repliedCount,
          totalCount: res?.data?.data?.totalCount,
        };
        yield put(MessagesActions.Message_Success(objMessages));
        //   Utils.showSnackBar({ message: res.data.data, severity: "success" });
      }
    }
  }

  static *SendMessage(payload) {
    const { id, reply, email } = payload.payload;

    const replyObj = { reply, email };
    try {
      let res = yield ApiCaller.Post(
        `${MESSAGES_ENDPOINT}/${id}/reply`,
        replyObj
      );

      if (res.status == 200) {
        yield put(MessagesActions.getMessage(payload.payload));
        Utils.showSnackBar({ message: res?.data?.data, severity: "success" });
      } else {
        // yield put(AuthAction.LogoutFailure());
        Utils.showSnackBar({ message: res?.error?.message, severity: "error" });
      }
    } catch (err) {
      yield put(MessagesActions.replyMessage_Failure());
    }
  }

  static *DeleteMessage(payload) {
    const { id, payloadObject } = payload.payload;
    try {
      let res = yield ApiCaller.Delete(`${MESSAGES_ENDPOINT}/${id}`);

      if (res.status == 200) {
        Utils.showSnackBar({
          message: res?.data?.data,
          severity: "success",
        });
        yield put(MessagesActions.getMessage(payloadObject));
      } else {
        Utils.showSnackBar({
          message: res?.error?.message,
          severity: "error",
        });
      }
    } catch (err) {}
  }
}
