import React, { useState } from "react";
import { Modal, Row, Col, Button, Input } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { SupportAction } from "../../store/actions";
import useMedia from "use-media";
import { useDispatch } from "react-redux";
import { Colors, Images } from "../../config";

const { TextArea } = Input;
var a, b;
const ModalComponent = (props) => {
  const {
    id,
    email,
    limit,
    offset,
    isBlockUser,
    isDelete,
    isModalVisible,
    handleOk,
    handleCancel,
    content,
    isProfileModal,
    supportID,
    deleteMessageLoader,
    isReply,
    isUpload,
    sendMessageLoader,
    maskClosable,
    isExpand,
    message,
    isMessage,
    isEmail,
    onAbc,
    // isModalVisibleUpload,
    isTagModal,
    isLanguage,
  } = props;
  const isWide = useMedia({ maxWidth: "485px" });
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  return (
    <>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={true}
        {...props}
        bodyStyle={{
          padding: isMessage || isTagModal ? "" : 35,
          //  borderRadius:10,
          paddingBottom: 35,
          paddingLeft: isEmail && !isWide ? 70 : "",
          paddingRight: isEmail && !isWide ? 70 : "",
          backgroundColor:
            isDelete || isEmail || isBlockUser || isUpload ? "white" : "",
          borderRadius: isDelete || isEmail || isBlockUser ? 10 : "",
        }}
        wrapClassName="modalTheme"
        className={
          isDelete || isBlockUser
            ? "Delete"
            : isMessage
            ? "Message"
            : isEmail
            ? "Email"
            : isTagModal
            ? "TagWork"
            : ""
        }
      >
        <Col span={24}>
          <Row
            justify="end"
            style={{
              width: "100%",
              // marginTop: -60,
            }}
          >
            <img
              style={{ marginRight: -44, cursor: "pointer" }}
              onClick={() => handleCancel()}
              src={Images.CloseIcon}
            />
          </Row>

          <Row gutter={[0, 15]}>
            <Col
              span={24}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: isDelete || isEmail || isBlockUser ? "black" : "",
                fontSize: isDelete || isBlockUser ? 14 : "",
                // marginTop:30
              }}
            >
              {content}
            </Col>
            <Col span={24}>
              {isReply || isExpand ? (
                <TextArea
                  readOnly={isExpand ? true : false}
                  rows={4}
                  maxLength={1000}
                  defaultValue={message}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                  }}
                  placeholder="Write Something..."
                />
              ) : null}
            </Col>
            <Col span={24} style={{ color: "red" }}>
              {error}
            </Col>

            {isMessage || isTagModal || isUpload ? (
              ""
            ) : (
              <Col
                xs={24}
                sm={
                  isReply || isExpand || isProfileModal || isEmail
                    ? 24
                    : isMessage
                    ? 16
                    : 12
                }
                md={
                  isReply || isExpand || isProfileModal || isEmail
                    ? 24
                    : isMessage
                    ? 20
                    : 12
                }
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  onClick={() => {
                    // isReply ? sendMessage() : isExpand ? handleCancel() : deleteSupportMessage();
                    isProfileModal ||
                    isDelete ||
                    isMessage ||
                    isEmail ||
                    isBlockUser ||
                    isUpload
                      ? handleOk()
                      : handleCancel();
                  }}
                  style={{
                    width:
                      isDelete || isMessage || isEmail || isBlockUser
                        ? "90%"
                        : "30%",

                    height: "2.5rem",
                    backgroundColor: "#54ac3f",
                    borderRadius: isDelete || isEmail || isBlockUser ? 10 : 5,
                    color: "white",
                  }}
                  loading={isReply ? sendMessageLoader : deleteMessageLoader}
                >
                  {isProfileModal
                    ? "Reset"
                    : isReply || isEmail
                    ? "Send"
                    : isExpand
                    ? "Cancel"
                    : isMessage || isLanguage
                    ? ""
                    : "Yes"}
                </Button>
              </Col>
            )}
            {isReply ||
            isExpand ||
            isProfileModal ||
            isEmail ? null : isMessage ||
              isUpload ||
              isLanguage ||
              isTagModal ? (
              ""
            ) : (
              <Col
                xs={24}
                sm={12}
                md={12}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  onClick={() => {
                    handleCancel();
                  }}
                  style={{
                    width: isDelete || isBlockUser ? "90%" : "80%",
                    height: "2.5rem",
                    backgroundColor: "#54ac3f",
                    borderRadius: isDelete || isEmail || isBlockUser ? 10 : 5,
                    color: "white",
                  }}
                >
                  No
                </Button>
              </Col>
            )}
          </Row>
        </Col>
      </Modal>
    </>
  );
};
export default React.memo(
  withRouter(
    connect((storeState) => ({
      // deleteMessageLoader: storeState.SupportReducer.deleteMessageLoader,
      // sendMessageLoader: storeState.SupportReducer.sendMessageLoader,
    }))(ModalComponent)
  )
);
