import React from 'react';
import {Colors,Images} from "../../config"
import styled from "styled-components";
const Box = styled.div`
  background-color: ${Colors.White};
  color: ${Colors.Black};
  border-radius: 10px;
  background-image:url(${Images.BackgroundImage});
  border-color: ${Colors.Primary};
  display: flex; 
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  background-repeat:no-repeat;
  background-size: cover;
  background-position: center 300px;
  `;
const LoginBox = (props) => {
  return (
    <>
  <Box  {...props}>
  </Box>
  </>);
}

export default React.memo(LoginBox)