import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Colors, Images } from "../../config";
import { RiShutDownLine } from "react-icons/ri";
import useMedia from "use-media";
import SideBar from "./sideBar";
import Footer from "./Footer";
import { AuthAction } from "../../store/actions";
import { Row, Col, Card, Avatar, Menu, Dropdown } from 'antd'

const DashboardHeader = ({
  customizeStyle,
  columnSpacing,
  rowSpacing,
  rowGap,
  click,
  ...props
}) => {
  const [checkUrl, setCheckUrl] = useState({
    mainHeading: 'Welcome to Dashboard', subHeading: ''
  })
  const { children } = props;
  const isWide = useMedia({ minWidth: "992px" });

  useEffect(() => {

    const pathName = window.location.pathname;
    if (pathName.includes('user')) return setCheckUrl({ mainHeading: "User Management" })
    if (pathName.includes('games')) return setCheckUrl({ mainHeading: "Games" })
    if (pathName.includes('winner')) return setCheckUrl({ mainHeading: "Winner Management", subHeading: 'List of Winner' })
    if (pathName.includes('messages')) return setCheckUrl({ mainHeading: "Messages" })
    if (pathName.includes('transactions')) return setCheckUrl({ mainHeading: "Transactions" })

  }, [window.location.pathname])




  // const isUserManagement = pathName.includes('user')
  // const isGames = pathName.includes('games')
  // const isWinnerManagement = pathName.includes('winner')
  // const isMessage = pathName.includes('message')
  // const isTransections = pathName.includes('transections')

  const menu = (
    <Menu>

      <Menu.Item>
        <div style={{ marginRight: "54px", cursor: "pointer" }}>
          <RiShutDownLine size={16} style={{ color: Colors.Danger }} /> Logout
        </div>
      </Menu.Item>

    </Menu>
  )


  return (
    <>


      <Row
        style={{ background: '#F5F7FB' }}>
        {isWide ? <SideBar /> : null}
        <Col
          // container
          // item
          xs={!isWide ? 24 : 22}
          sm={!isWide ? 24 : 22}
          md={24}
          lg={19}
          style={{ height: "100%" }}
        >
         

          <Row justify="center">
            <Col span={23}>
              {children}
            </Col>
          </Row>

          {!isWide ? <Footer /> : null}

        </Col>
      </Row>
    </>
  );
};

export default DashboardHeader;
