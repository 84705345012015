import {
  USER_STATS,
  USER_STATS_SUCCESS,
  USER_STATS_FAILURE,
  FREE_PAID_GAMES,
  FREE_PAID_GAMES_SUCCESS,
  FREE_PAID_GAMES_FAILURE,
  TOTAL_GAMES,
  TOTAL_GAMES_SUCCESS,
  TOTAL_GAMES_FAILURE,
  SIGNUP_STATS,
  SIGNUP_STATS_SUCCESS,
  SIGNUP_STATS_FAILURE,
} from "../constants";

const initialState = {
  // isLoading: false,
  userStats: [],
  freePaidGames: [],
  totalGamesPlayedGraph: [],
  signupStats: [],
};

export default function DashboardReducer(state = initialState, action) {
  switch (action.type) {
    case USER_STATS:
      {
        state = {
          ...state,
          // isLoading: true,
        };
      }
      break;
    case USER_STATS_SUCCESS:
      {
        state = {
          ...state,
          // isLoading: false,
          userStats: action.userStats,
        };
      }
      break;

    case USER_STATS_FAILURE:
      {
        state = {
          ...state,
          // isLoading: false,
        };
      }
      break;

    case FREE_PAID_GAMES:
      {
        state = {
          ...state,
          // isLoading: true,
        };
      }
      break;

    case FREE_PAID_GAMES_SUCCESS:
      {
        let abc = Object?.keys(action?.payload) || [];
        const freePaidGraph = (Object?.keys(action?.payload) || []).map(
          (item, index) => {
            return {
              name: item.slice(0, 3),
              free: action?.payload[item]["free"],
              paid: action?.payload[item]["paid"],
            };
          }
        );

        state = {
          ...state,
          // isLoading: false,
          freePaidGames: freePaidGraph,
        };
      }
      break;

    case FREE_PAID_GAMES_FAILURE:
      {
        state = {
          ...state,
          // isLoading: false,
        };
      }
      break;

    case TOTAL_GAMES:
      {
        state = {
          ...state,
          // isLoading: true,
        };
      }
      break;

    case TOTAL_GAMES_SUCCESS:
      {
        let totalGamesArr = (Object.keys(action.payload) || []).map((items) => {
          return {
            name: items.slice(0, 3),
            totalGames: action?.payload[items],
          };
        });
        state = {
          ...state,
          // isLoading: false,
          totalGamesPlayedGraph: totalGamesArr,
        };
      }
      break;

    case TOTAL_GAMES_FAILURE:
      {
        state = {
          ...state,
          // isLoading: false,
        };
      }
      break;

    case SIGNUP_STATS:
      {
        state = {
          ...state,
          // isLoading: true,
        };
      }
      break;

    case SIGNUP_STATS_SUCCESS:
      {
        let signupStatsArr = (Object.keys(action.payload) || []).map(
          (items) => {
            return {
              name: items,
              apple: action.payload[items]["apple"],
              google: action.payload[items]["google"],
            };
          }
        );
        state = {
          ...state,
          // isLoading: false,
          signupStats: signupStatsArr,
        };
      }
      break;

    case SIGNUP_STATS_FAILURE:
      {
        state = {
          ...state,
          // isLoading: false,
        };
      }
      break;

    default:
      break;
  }
  return state;
}
