import React from "react";
import propTypes from "prop-types";
import { Row, Col } from "antd";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import { withTheme } from "styled-components";
import camelCaseToTitle from "../../config/util/index";
var dataKey;
const CustomTooltip = ({ payload }) => {
  return (
    <>
      {(payload || []).map((item) => {
        if (item.value === dataKey) {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                item
                style={{
                  border: "1px solid #c9c8c7",
                  whiteSpace: "nowrap",
                  width: "auto",
                  height: "80px",
                  borderRadius: "5px",
                  padding: "10px",
                }}
              >
                <p
                  className="recharts-tooltip-label"
                  style={{
                    color: "black",
                  }}
                >
                  <p style={{ color: "#F2CC2D", fontWeight: "bold" }}>
                    {item.payload.totalGames || item.payload.totalGames == 0
                      ? ` Total Games:  ${item.payload.totalGames}`
                      : ` Free: ${item.payload.free}`}
                  </p>
                  {item.payload.totalGames ||
                  item.payload.totalGames == 0 ? null : (
                    <p
                      style={{ color: "#55AD3F", fontWeight: "bold" }}
                    >{`Paid: ${item.payload.paid}`}</p>
                  )}

                  {/* {item.payload.android
                    ? item.payload.android
                    : item.payload.iOS
                    ? item.payload.iOS
                    : item.payload.user} */}
                  <br />
                  {/* {item.payload.name} */}
                </p>
              </div>
            </div>
          );
        }
      })}
    </>
  );
};
class renderLineChart extends React.Component {
  state = {
    updateXAxis: false,
  };
  handleClick = (e) => {
    dataKey = e.activeLabel;
  };
  componentWillUpdate(nextProps) {
    if (this.props.data !== nextProps.data) {
      this.setState({ updateXAxis: !this.state.updateXAxis });
    }
  }

  // Lengeds start

  renderLegend = (props) => {
    const { payload } = props;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {payload
          .filter((item) => item.dataKey !== "name")

          .map((entry, index) => {
            return (
              <>
                <div
                  style={{
                    borderRadius: 15,
                    width: 15,
                    height: 15,
                    backgroundColor: entry.payload.stroke,
                    margin: 20,
                  }}
                ></div>
                <p style={{ marginBottom: 0, textTransform: "capitalize" }}>
                  {entry.value}
                </p>
              </>
              // key={`item-${index}`}>{entry.value}
            );
          })}
      </div>
    );
  };

  render() {
    var obj;
    if (this.props.data[0]) obj = Object.keys(this.props.data[0]);
    const COLORS = ["#55AD3F", "#F2CC2D"];
    return (
      <div>
        {/* <LineChart width={this.props.width} height={this.props.height} data={this.props.data.length !== 0 ? this.props.data : [{ empty: 0 }]} margin={this.props.margin}> */}
        <LineChart
          width={this.props.width}
          height={this.props.height}
          data={this.props.data.length !== 0 ? this.props.data : [{ empty: 0 }]}
          margin={this.props.margin}
          onMouseMove={(e) => {
            this.handleClick(e);
          }}
        >
          <XAxis
            dataKey="name"
            padding={{ left: 30, right: 30 }}
            axisLine={{ stroke: "#fde8f1" }}
          />
          <Tooltip content={<CustomTooltip />} />
          <YAxis padding={{ top: 155 }} axisLine={{ stroke: "#fde8f1" }} />
          {obj.map((item, index) => {
            return (
              <Line
                key={index.toString()}
                type={this.props.lineType}
                dataKey={obj[index]}
                stroke={index == "2" ? "#54AC3F" : "#F2CC2D"}
              />
            );
          })}

          <Legend
            verticalAlign="bottom"
            height={36}
            content={this.renderLegend}
          />
        </LineChart>
      </div>
    );
  }
}

renderLineChart.defaultProps = {
  width: "100%",
  height: 300,
  margin: { top: 0, right: 0, left: 0, bottom: 0 },
  lineType: "monotone",
  isCartReq: false,
  cartStroke: "#f0f0f0",
  cartDash: 5,
  isTooltip: true,
};

renderLineChart.propTypes = {
  data: propTypes.array.isRequired,
  lineColor: propTypes.array.isRequired,
};
export default withTheme(renderLineChart);
