import { DashboardAction } from "../actions";
import { ApiCaller } from "../../config";
import { put, delay } from "redux-saga/effects";
import { Utils } from "../../config";

import {
  USER_STATS_ENDPOINT,
  FREEPAID_GAMES,
  TOTALGAMES,
  SIGNUPSTATS,
} from "../../config/variables";
export default class DashboardMiddleware {
  static *GetUserStats() {
    try {
      let res = yield ApiCaller.Get(`${USER_STATS_ENDPOINT}`);

      if (res.status == 200) {
        const objUsersStats = {
          totalUsers: res?.data?.data?.totalUsers,
          totalPayout: res?.data?.data?.totalPayout,
          totalPaid: res?.data?.data?.totalPaidUsers,
        };
        yield put(DashboardAction.userStats_Success(objUsersStats));
      } else {
        yield put(DashboardAction.userStats_Failure());
        Utils.showSnackBar({
          message: res.data.error.message,
          severity: "error",
        });
      }
    } catch (err) {
      // yield put(DashboardAction.GetUsersCardCountFailure());
      yield put(DashboardAction.userStats_Failure());
    }
  }
  static *GetFreeAndPaidGames(payload) {
    const { id, startDate, endDate } = payload.payload;
    try {
      let res = yield ApiCaller.Get(
        `${FREEPAID_GAMES}?startDate=${startDate}&endDate=${endDate}&gameId=${id}`
      );

      if (res.status == 200) {
        yield put(DashboardAction.getFreeAndPaidGames_Success(res.data.data));
      } else {
        yield put(DashboardAction.getFreeAndPaidGames_Failure());
        Utils.showSnackBar({
          message: res.data.error.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(DashboardAction.getFreeAndPaidGames_Failure());
    }
  }

  static *GetTotalGamesPLayed(payload) {
    const { startDate, endDate } = payload.payload;
    try {
      let res = yield ApiCaller.Get(
        `${TOTALGAMES}?startDate=${startDate}&endDate=${endDate}`
      );

      if (res.status == 200) {
        yield put(DashboardAction.totalGamesPlayed_Success(res.data.data));
      } else {
        yield put(DashboardAction.totalGamesPlayed_Failure());
        Utils.showSnackBar({
          message: res.data.error.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(DashboardAction.totalGamesPlayed_Failure());
    }
  }

  static *GetSignUp_Stats(payload) {
    const { startDate, endDate } = payload.payload;
    try {
      let res = yield ApiCaller.Get(
        `${SIGNUPSTATS}?startDate=${startDate}&endDate=${endDate}`
      );

      if (res.status == 200) {
        yield put(DashboardAction.getSignupStats_Success(res.data.data));
      } else {
        yield put(DashboardAction.getSignupStats_Failure());
        Utils.showSnackBar({
          message: res.data.error.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(DashboardAction.getSignupStats_Failure());
    }
  }
}
