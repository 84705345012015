import React from "react";
import Grid from "@mui/material/Grid";
import { Colors, Images } from "../../config";
import { Link } from "react-router-dom";
import { Row, Col, Menu, Switch, Divider } from "antd";
import { AiFillMessage, AiFillProfile } from "react-icons/ai";
import { FaChartPie, FaUserFriends, FaGamepad, FaTrophy } from "react-icons/fa";

const SideBar = ({ ...props }) => {
  const pathName = window.location.pathname;
  //   const isUser = pathName.includes("users");
  const isOrder = pathName.includes("orders");
  const isNews = pathName.includes("news");
  const isReported = pathName.includes("reported");
  // ==============================================
  const isMessage = pathName.includes("messages");
  const isWinnerManagement = pathName.includes("winner");
  const isGames = pathName.includes("games");
  const isUserManagement = pathName.includes("user-management");
  const isTransaction = pathName.includes("transaction");

  const isDashboard =
    !isWinnerManagement &&
    !isMessage &&
    !isGames &&
    !isUserManagement &&
    !isTransaction;
  return (
    <>
      <Col
        xs={0}
        sm={3}
        md={3}
        lg={5}
        style={{
          background: "#FFFFFF",
          borderTopRightRadius: "30px 30px",
          boxShadow:
            "rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
        }}
      >
        <Row justify="center" style={{ marginTop: "2rem" }}>
          <Col span={24} align="middle">
            <img src={Images.Logo} style={{ width: "100px" }}></img>
          </Col>
          <Col span={24} align="middle" style={{ marginTop: "2rem" }}>
            <p className="menuPara">Play Pound Profit.</p>
            {/* <Divider style={{margin:''}}/> */}
            <hr style={{ width: "94%", borderTop: "1px solid #fafafa" }} />
          </Col>
        </Row>
        <Menu
          style={{
            minHeight: "100vh",
            background: Colors.White,
            borderTopRightRadius: "30px 30px",
          }}
        >
          <Menu.Item
            key="1"
            icon={
              <FaChartPie
                size={20}
                style={{
                  color: isDashboard ? "#FFFFFF" : "",
                }}
              />
            }
            className={isDashboard ? "sidebarTextActive" : "sidebarText"}
          >
            <Link
              to="/"
              style={{
                color: isDashboard ? "#FFFFFF" : "",
              }}
            >
              Dashboard
            </Link>
          </Menu.Item>
          <Menu.Item
            key="2"
            icon={
              <FaUserFriends
                size={20}
                style={{
                  color: isUserManagement ? "#FFFFFF" : "",
                }}
              />
            }
            className={isUserManagement ? "sidebarTextActive" : "sidebarText"}
          >
            <Link
              to="/user-management"
              style={{
                color: isUserManagement ? "#FFFFFF" : "",
              }}
            >
              User Management
            </Link>
          </Menu.Item>
          <Menu.Item
            key="3"
            icon={
              <FaGamepad
                size={20}
                style={{
                  color: isGames ? "#FFFFFF" : "",
                }}
              />
            }
            className={isGames ? "sidebarTextActive" : "sidebarText"}
          >
            <Link
              to="/games"
              style={{
                color: isGames ? "#FFFFFF" : "",
              }}
            >
              Games
            </Link>
          </Menu.Item>
          <Menu.Item
            key="4"
            icon={
              <FaTrophy
                size={20}
                style={{
                  color: isWinnerManagement ? "#FFFFFF" : "",
                }}
              />
            }
            className={isWinnerManagement ? "sidebarTextActive" : "sidebarText"}
          >
            <Link
              to="/winner-management"
              style={{
                color: isWinnerManagement ? "#FFFFFF" : "",
              }}
            >
              Winner Management
            </Link>
          </Menu.Item>
          <Menu.Item
            key="5"
            icon={
              <AiFillMessage
                size={20}
                style={{
                  color: isMessage ? "#FFFFFF" : "",
                }}
              />
            }
            className={isMessage ? "sidebarTextActive" : "sidebarText"}
          >
            <Link
              to="/messages"
              style={{
                color: isMessage ? "#FFFFFF" : "",
              }}
            >
              Message
            </Link>
          </Menu.Item>
          <Menu.Item
            key="6"
            icon={
              <AiFillProfile
                size={20}
                style={{
                  color: isTransaction ? "#FFFFFF" : "",
                }}
              />
            }
            className={isTransaction ? "sidebarTextActive" : "sidebarText"}
          >
            <Link
              to="/transaction"
              style={{
                color: isTransaction ? "#FFFFFF" : "",
              }}
            >
              Transactions
            </Link>
          </Menu.Item>
        </Menu>
      </Col>
    </>
  );
};

export default SideBar;
