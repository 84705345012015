import {
  USER_STATS,
  USER_STATS_SUCCESS,
  USER_STATS_FAILURE,
  FREE_PAID_GAMES,
  FREE_PAID_GAMES_SUCCESS,
  FREE_PAID_GAMES_FAILURE,
  TOTAL_GAMES,
  TOTAL_GAMES_SUCCESS,
  TOTAL_GAMES_FAILURE,
  SIGNUP_STATS,
  SIGNUP_STATS_SUCCESS,
  SIGNUP_STATS_FAILURE,
} from "../constants";

export default class DashboardAction {
  static userStats() {
    return {
      type: USER_STATS,
    };
  }
  static userStats_Success(userData) {
    return {
      type: USER_STATS_SUCCESS,
      userStats: userData,
    };
  }
  static userStats_Failure() {
    return {
      type: USER_STATS_FAILURE,
    };
  }

  static getFreeAndPaidGames(payload) {
    return {
      type: FREE_PAID_GAMES,
      payload,
    };
  }
  static getFreeAndPaidGames_Success(payload) {
    return {
      type: FREE_PAID_GAMES_SUCCESS,
      payload,
    };
  }
  static getFreeAndPaidGames_Failure() {
    return {
      type: FREE_PAID_GAMES_FAILURE,
    };
  }

  static getTotalGamesPlayed(payload) {
    return {
      type: TOTAL_GAMES,
      payload,
    };
  }
  static totalGamesPlayed_Success(payload) {
    return {
      type: TOTAL_GAMES_SUCCESS,
      payload,
    };
  }
  static totalGamesPlayed_Failure() {
    return {
      type: TOTAL_GAMES_FAILURE,
    };
  }

  static getSignupStats(payload) {
    return {
      type: SIGNUP_STATS,
      payload,
    };
  }
  static getSignupStats_Success(payload) {
    return {
      type: SIGNUP_STATS_SUCCESS,
      payload,
    };
  }
  static getSignupStats_Failure() {
    return {
      type: SIGNUP_STATS_FAILURE,
    };
  }
}
