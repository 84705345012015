import React from "react";
import { Row, Col, Card, Avatar } from "antd";
import "./style.css";

const UserCards = (props) => {
 
  const { mainHeading, subHeading, img, customeStyle } = props;

  const objStyle = {
    background: "red",
  };
  return (
    <Card className={`cardBody ${customeStyle}`} bordered={false}>
      <Row justify="space-between" align="middle">
        <Col span={16}>
          <Row>
            <Col span={24}>
              <h1 className="headOne">{mainHeading}</h1>
            </Col>
            <Col span={24}>
              <p className="subHead">{subHeading}</p>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Row justify="end">
            <img src={img} width={50} />
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
export default UserCards;
