import React, { useEffect, useState } from "react";
import { Layout } from "../../components";
import Header from "../../components/Layout/Header";
import { useMedia } from "use-media";
import {
  TransactionActions,
  DashboardAction,
  GamesActions,
} from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Button, DatePicker, Input, Select } from "antd";
import TransactionBox from "./TransactionBox";
import moment from "moment";
import {
  AiFillCalendar,
  AiOutlinePercentage,
  AiFillCaretDown,
} from "react-icons/ai";
import { Utils, Colors } from "../../config";

const Transaction = () => {
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const dispatch = useDispatch();
  const isXs = useMedia({ minWidth: "576px" });
  const isSm = useMedia({ minWidth: "290px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [gameId, setGameId] = useState(0);
  const [errors, setErrors] = useState({
    payInDateError: "",
    payOutDateError: "",
    gameError: "",
  });

  useEffect(() => {
    dispatch(TransactionActions.PayInDetailsRequest());
    dispatch(TransactionActions.PayOutDetailsRequest());
    dispatch(GamesActions.GetFilterGames());
  }, []);

  const payInDetailsData = useSelector((store) => {
    return store?.TransactionReducer?.payInDetailsData;
  });
  const payOutDetailsData = useSelector((store) => {
    return store?.TransactionReducer?.payOutDetailsData;
  });
  let isIn = true;
  let isOut = true;

  const allGames = useSelector((store) => {
    return store?.GamesReducer?.allGames;
  });

  let games = [{ name: "View All", value: "" }, ...allGames];
  const {
    amountPaidByTheUsers,
    amountReceivedByAdmin,
    amountUsedByUsers,
    paypalsCut,
  } = payInDetailsData;

  const { amountPaidToWinners, adminProfit } = payOutDetailsData;

  const headerProps = {
    mainHeading: "Transactions",
    subHeading: "",
  };

  const handleChangeDate = (e, value) => {
    if (!e && value == "isIn") {
      dispatch(TransactionActions.PayInDetailsRequest());
      return;
    }
    if (!e && value == "isOut") {
      dispatch(TransactionActions.PayOutDetailsRequest());
      return;
    }
    let startDate = moment(e[0])?.toISOString();
    let endDate = moment(e[1])?.toISOString();
    setStartDate(startDate);
    setEndDate(endDate);
    setErrors({ ...errors, payInDateError: "", payOutDateError: "" });
  };
  const handleChangeGameId = (e) => {
    setGameId(e);
    setErrors({ ...errors, gameError: "" });
  };

  const payInobj = { startDate, endDate };
  const payOutObj = { startDate, endDate, gameId };
  const handlePaypalInDetails = (payInobj) => {
    const { startDate, endDate } = payInobj;
    if (!startDate && !endDate) {
      setErrors((errors) => ({
        ...errors,
        payInDateError: "Please Select the date",
      }));
      return;
    } else {
      dispatch(TransactionActions.PayInDetailsRequest({ startDate, endDate }));
    }
  };

  const handlePaypalOutDetails = (payOutobj) => {
    const { startDate, endDate, gameId } = payOutobj;
    if ((!startDate && !endDate) || gameId == 0) {
      if (!startDate && !endDate) {
        setErrors((errors) => ({
          ...errors,
          payOutDateError: "Please Select the date",
        }));
        return;
      }
      if (gameId == 0 || gameId !== "") {
        setErrors((errors) => ({
          ...errors,
          gameError: "Please Select the game",
        }));
        return;
      }
    } else {
      dispatch(
        TransactionActions.PayOutDetailsRequest({
          startDate,
          endDate,
          gameId,
        })
      );
    }
  };

  const payInLoading = useSelector(
    (state) => state?.TransactionReducer?.payInDetailsLoading
  );
  const payOutLoading = useSelector(
    (state) => state?.TransactionReducer?.payOutDetailsLoading
  );

  return (
    <Layout.DashboardHeader
      columnSpacing={5}
      customizeStyle={{
        display: "flex",
        padding: 25,
        backgroundColor: "#fffcfc",
        marginLeft: 2,
      }}
    >
      <Header headings={headerProps} />
      <Row justify="center">
        <Col
          span={24}
          style={{
            backgroundColor: "white",
            borderRadius: 10,
            height: "100vh",
            padding: 10,
          }}
        >
          <Row gutter={[0, 30]}>
            <Col span={24}>
              <Row gutter={[0, 30]}>
                <Col
                  span={24}
                  style={{
                    fontSize: isXxl ? "24px" : isXl ? "20px" : "22px",
                    fontWeight: "bold",
                  }}
                >
                  Paypal Pay-in Details
                </Col>
                <Col span={24}>
                  <Row gutter={[20, 20]}>
                    <Col xs={24} sm={24} md={10} lg={8} xl={8} xxl={8}>
                      <RangePicker
                        // bordered={false}
                        suffixIcon={<AiFillCalendar />}
                        style={{ cursor: "pointer", width: "100%" }}
                        size="large"
                        onChange={(e) => handleChangeDate(e, "isIn")}
                      />
                      <label style={{ color: "red" }}>
                        {errors?.payInDateError}
                      </label>
                    </Col>
                    <Col xs={24} sm={24} md={6} lg={4} xl={4} xxl={3}>
                      <Button
                        style={{
                          whiteSpace: "normal",
                          height: "2.5rem",
                          backgroundColor: "#54ac3f",
                          backgroundImage: "linear-gradient(#5FB23F, #1E8F3E)",
                          borderRadius: 10,
                          color: "white",

                          width: "100%",
                        }}
                        onClick={() => handlePaypalInDetails(payInobj)}
                        loading={payInLoading}
                      >
                        {"Search"}
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[15, 15]}>
                    <Col xs={24} sm={24} md={8} lg={8} xl={6} xxl={6}>
                      <TransactionBox
                        amount={amountPaidByTheUsers}
                        text="Total amount paid by the users"
                      />
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={6} xxl={6}>
                      <TransactionBox
                        amount={amountReceivedByAdmin}
                        text="Total amount received by admin"
                      />
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={6} xxl={6}>
                      <TransactionBox
                        amount={paypalsCut}
                        text="Total paypal's cut"
                      />
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={8} xl={6} xxl={6}>
                      <TransactionBox
                        amount={amountUsedByUsers}
                        text="Total amount used by users"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[0, 30]}>
                <Col
                  style={{
                    fontSize: isXxl ? "24px" : isXl ? "20px" : "22px",
                    fontWeight: "bold",
                  }}
                >
                  Paypal Pay-Out Details
                </Col>
                <Col span={24}>
                  <Row gutter={[20, 20]}>
                    <Col xs={24} sm={24} md={10} lg={8} xl={8} xxl={8}>
                      <RangePicker
                        // bordered={false}
                        suffixIcon={<AiFillCalendar />}
                        style={{ cursor: "pointer", width: "100%" }}
                        size="large"
                        onChange={(e) => handleChangeDate(e, "isOut")}
                      />
                      <label style={{ color: "red" }}>
                        {errors?.payOutDateError}
                      </label>
                    </Col>
                    <Col xs={24} sm={24} md={10} lg={6} xl={8} xxl={4}>
                      <Select
                        defaultValue="Select Game"
                        suffixIcon={<AiFillCaretDown />}
                        className="dashboardGame_filter"
                        size="large"
                        style={{
                          width: "100%",
                        }}
                        onChange={(e) => handleChangeGameId(e)}
                      >
                        {(games || []).map((item) => {
                          return (
                            <>
                              <Option value={item.id}>{item.name}</Option>
                            </>
                          );
                        })}
                      </Select>
                      <label style={{ color: "red" }}>
                        {errors?.gameError}
                      </label>
                    </Col>
                    <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={3}>
                      <Button
                        style={{
                          whiteSpace: "normal",
                          height: "2.5rem",
                          backgroundImage: "linear-gradient(#5FB23F, #1E8F3E)",
                          color: "white",
                          borderRadius: 10,

                          width: "100%",
                        }}
                        loading={payOutLoading}
                        onClick={() => handlePaypalOutDetails(payOutObj)}
                      >
                        {"Search"}
                      </Button>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row gutter={[20, 0]}>
                    <Col span={6}>
                      <TransactionBox
                        amount={amountPaidToWinners}
                        text="Total amount paid to winners"
                      />
                    </Col>
                    <Col span={6}>
                      <TransactionBox
                        amount={adminProfit}
                        text="Total profit of admin"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout.DashboardHeader>
  );
};

export default Transaction;
