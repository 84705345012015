import {
  SIGNIN,
  SIGNIN_SUCCESS,
  SIGNIN_FAILURE,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  RESETPASSWORD,
  RESETPASSWORD_SUCCESS,
  RESETPASSWORD_FAILURE,
  FORGETPASSWORD,
  FORGETPASSWORD_SUCCESS,
  FORGETPASSWORD_FAILURE,
} from "../constants";

const initialState = {
  user: localStorage.getItem("PPP_Auth")
    ? JSON.parse(localStorage.getItem("PPP_Auth"))
    : null,
  loader: false,
  resetLoader: false,
  posts: [],
};

export default function AppReducer(state = initialState, action) {
  switch (action.type) {
    case SIGNIN:
      state = {
        ...state,
        loader: true,
      };
      break;
    case SIGNIN_SUCCESS:
      state = {
        ...state,
        user: action.payload,
        loader: false,
      };
      break;
    case SIGNIN_FAILURE:
      state = {
        ...state,
        loader: false,
      };
      break;

    case FORGETPASSWORD:
      state = {
        ...state,
        loader: true,
      };
      break;
    case FORGETPASSWORD_SUCCESS:
      state = {
        ...state,
        user: action.payload,
        loader: false,
      };
      break;

    case FORGETPASSWORD_FAILURE:
      state = {
        ...state,
        loader: false,
      };
      break;

    case RESETPASSWORD:
      state = {
        ...state,
        resetLoader: true,
      };
      break;
    case RESETPASSWORD_SUCCESS:
      state = {
        ...state,
        // user: action.payload,
        resetLoader: false,
      };
      break;
    case RESETPASSWORD_FAILURE:
      state = {
        ...state,
        resetLoader: false,
      };
      break;

    case LOGOUT:
      state = {
        ...state,
        loader: true,
      };
      break;
    case LOGOUT_SUCCESS:
      state = {
        user: {},
        posts: [],
        loader: false,
      };
      break;
    case LOGOUT_FAILURE:
      state = {
        ...state,
        loader: false,
      };
      break;

    default:
      break;
  }

  return state;
}
