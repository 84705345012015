import React from "react";
import { Row, Col, Card, Avatar, Menu, Dropdown } from "antd";
import { useMedia } from "use-media";
import { RiShutDownLine } from "react-icons/ri";
import { Colors } from "../../config";
import { useDispatch } from "react-redux";
import { AuthAction } from "../../store/actions";
import { AiOutlineUser } from "react-icons/ai";

const TransactionBox = (props) => {
  const isXs = useMedia({ minWidth: "576px" });
  const isSm = useMedia({ minWidth: "290px" });
  const isMd = useMedia({ minWidth: "768px" });
  const isLg = useMedia({ minWidth: "992px" });
  const isXl = useMedia({ minWidth: "1200px" });
  const isXxl = useMedia({ minWidth: "1600px" });
  const { amount, text } = props;
  return (
    <Row
      justify="center"
      align="middle"
      style={{
        background: "#F5F5F5",
        borderRadius: "10px",
        height: isXxl ? "180px" : "120px",
      }}
    >
      <Col span={20}>
        <Row justify="center">
          <Col
            span={24}
            align="middle"
            style={{
              fontSize: isXxl ? "42px" : "35px",
              color: "#3A3A3A",
              fontWeight: "700",
            }}
          >
            £{amount?.toFixed(2) || 0}
          </Col>
          <Col
            span={24}
            align="middle"
            style={{ fontSize: isXxl ? "16px" : "12px" }}
          >
            {text}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default TransactionBox;
