import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Fonts, Utils } from "../../config";
import { AuthAction } from "../../store/actions";
import { Images, Colors } from "../../config";
import { RiMailFill, RiCheckboxCircleFill } from "react-icons/ri";
import { AiFillLock } from "react-icons/ai";
import { Row, Col, Input } from "antd";

const VerifyCode = (props) => {
  const [code, setCode] = useState();
  const [codeErrorMsg, setCodeErrorMsg] = useState("");
  const [validCode, setValidCode] = useState(true);
  let [showText, setShowText] = useState(true);
  let [timer, setTimer] = useState(40);

  const dispatch = useDispatch();
  const history = useHistory();
  const email = props?.location?.state;

  // var timeLeft = 0;
  let timerId;
  function countdown() {
    if (timer === 1) {
      clearInterval(timerId);
      setShowText(false);
      setTimer(40);
    } else {
      setTimer(timer--);
    }
  }

  const verifyCode = () => {
    // if (!code || !validCode) {
    if (!code) {
      setCodeErrorMsg("Required fields can not be left empty.");
      // setValidCode(false);
    } else if (!validCode) {
      setCodeErrorMsg("Please enter a valid code.");
    } else {
      const objData = { code: Number(code), email };
      dispatch(
        AuthAction.VerifyCode(objData, () =>
          history.replace("/update-password", email)
        )
      );
    }
  };
  const handleChanged = (event) => {
    if (event.target.value == " ") {
      setCodeErrorMsg("Spaces are not allowed");
    } else if (isNaN(event.target.value)) {
      setCodeErrorMsg("Only numbers are allowed");
    } else {
      setCode(event.target.value);
      handleErrors(event);
    }
  };

  const handleErrors = (a) => {
    let error = "";
    if (a.length == 0) {
      error = "Please enter the code ";
    } else if (a.length < 4 && a.length > 0) {
      setValidCode(false);
      error = "Incomplete Code ";
    } else {
      setValidCode(true);
      error = "";
    }
    setCodeErrorMsg(error);
    return error;
  };

  useEffect(() => {
    timerId = setInterval(countdown, 1000);
  }, [showText]);

  const resendCode = () => {
    const email = localStorage.getItem("email");
    dispatch(AuthAction.ForgetPassword(email, () => {}));
  };
  return (
    <Row justify="center">
      <Col className="imgColumn" xs={0} sm={0} md={12} lg={12}>
        <Row justify="center" style={{ padding: "3rem 0rem" }}>
          <Col span={18}>
            <Row justify="center">
              <Col>
                <h1 className="headingOne">Get Better at Gaming!</h1>
              </Col>
              <Col>
                <h1 className="regularHead">Dedicate Time to Playing Games.</h1>
              </Col>
              <Col>
                <img src={Images.SignInPhone} className="Box1_PhoneImg" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Col span={12} className="formColumn" xs={24} sm={24} md={12} lg={12}>
        <Row justify="center">
          <Col span={18}>
            <Row justify="left" style={{ padding: "3rem 0rem" }}>
              <Col span={24}>
                <img src={Images.Logo} />
              </Col>

              <Col span={24}>
                <h1 className="headingOne">Enter 4 Digits Code</h1>
              </Col>

              <Col span={24}>
                <h2 className="regularHead" style={{ fontSize: "18px" }}>
                  A one-time password (OTP), also known as a one-time <br />
                  PIN, one-time authorization
                </h2>
              </Col>

              <Col span={24}>
                <Row>
                  <Col className="colEmail" span={24}>
                    <Input
                      type={"text"}
                      value={code}
                      className="inputStyles"
                      maxLength={4}
                      onChange={(e) => handleChanged(e)}
                      customStyle={{
                        backgroundColor: "white",
                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            borderColor: Colors.Border,
                          },
                        "& .MuiOutlinedInput-root:hover": {
                          "& > fieldset": {
                            borderColor: Colors.Primary,
                          },
                        },
                      }}
                    />
                    <span style={{ color: Colors.Danger }}>
                      {" "}
                      {codeErrorMsg}{" "}
                    </span>
                  </Col>
                  <Col span={24}>
                    <p
                      onClick={
                        !showText
                          ? () => {
                              setShowText(!showText);
                              resendCode();
                            }
                          : () => {}
                      }
                      className="txt_resendCode"
                    >
                      {showText ? `00:${timer}` : "Resend code"}
                    </p>
                  </Col>
                  {/* <Col span={24}>
                    {showText ? (
                      <p className="showText">showText ? `00:${timer}` Resend code</p>
                    ) : (
                      <p>{timeLeft}</p>
                    )}
                  </Col> */}
                  <Col span={24} style={{ marginTop: "2rem" }}>
                    <button
                      className="btn btnLogin"
                      size="large"
                      onClick={() => verifyCode()}
                    >
                      SUBMIT
                    </button>
                  </Col>
                  <Col span={24} style={{ marginTop: "1rem" }}>
                    <p
                      className="txt_forgotPass"
                      onClick={() => history.push("/login")}
                    >
                      Back to Login
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default VerifyCode;
