import { GamesActions, WinnersAction } from '../actions'
import { ApiCaller } from "../../config";
import { put, delay } from "redux-saga/effects";
import { Utils } from "../../config";
import { GAMES_ENDPOINT } from "../../config/variables";

export default class DashboardMiddleware {
    static *GetGames() {

        try {
            let res = yield ApiCaller.Get(`${GAMES_ENDPOINT}`);

            if (res.status == 200) {
                yield put(GamesActions.GetGames_Success(res.data.data));
            } else {
                // yield put(DashboardAction.GetUsersCardCountFailure());
                Utils.showSnackBar({ message: res.data.error.message, severity: "error" })
            }
        } catch (err) {
            // yield put(DashboardAction.GetUsersCardCountFailure());
        }
    }

    static *GetFilterdGames(data) {
        try {
            let res = yield ApiCaller.Get(`${GAMES_ENDPOINT}`);

            if (res.status == 200) {
                yield put(GamesActions.GetFilter_Success(res.data.data));
            } else {
                // yield put(DashboardAction.GetUsersCardCountFailure());
                Utils.showSnackBar({ message: res.data.error.message, severity: "error" })
            }
        } catch (err) {
            // yield put(DashboardAction.GetUsersCardCountFailure());
        }

    }
}