import {
  MESSAGE,
  MESSAGE_SUCCESS,
  MESSAGE_FAILURE,
  REPLY_MESSAGE,
  REPLY_MESSAGE_SUCCESS,
  REPLY_MESSAGE_FAILURE,
  DELETE_MESSAGE,
  DELETE_MESSAGE_SUCCESS,
  DELETE_MESSAGE_FAILURE,
} from "../constants";
const initialState = {
  pendingCount: "",
  repliedCount: "",
  totalCount: "",
  allMessages: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case MESSAGE:
      state = {
        ...state,
        isLoading: true,
      };
      break;
    case MESSAGE_SUCCESS:
      
      state = {
        ...state,
        isLoading: false,
        pendingCount: action?.payload?.pendingCount,
        repliedCount: action?.payload?.repliedCount,
        totalCount: action?.payload?.totalCount,
        allMessages: action?.payload?.messages,
      };
      break;
    case MESSAGE_FAILURE:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case REPLY_MESSAGE:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case REPLY_MESSAGE_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case REPLY_MESSAGE_FAILURE:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case DELETE_MESSAGE:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case DELETE_MESSAGE_SUCCESS:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    case DELETE_MESSAGE_FAILURE:
      state = {
        ...state,
        isLoading: false,
      };
      break;
    default:
      break;
  }
  return state;
}
