import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import UserReducer from "./UserReducer";
import DashboardReducer from "./DashboardReducer";
import GamesReducer from "./GamesReducer";
import WinnerReducer from "./WinnerReducer";
import MessageReducer from "./MessagesReducer";
import TransactionReducer from "./TransactionReducers";

const RootReducer = combineReducers({
  AuthReducer,
  UserReducer,
  DashboardReducer,
  GamesReducer,
  WinnerReducer,
  MessageReducer,
  TransactionReducer,
});

export default RootReducer;
