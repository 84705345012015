import React, { useEffect, useState } from "react";
import { Layout } from "../../components";
import Header from "../../components/Layout/Header";
import { useDispatch, useSelector } from "react-redux";
import { MessagesActions } from "../../store/actions";
import { Utils } from "../../config";
import moment from "moment";

import {
  Row,
  Col,
  Select,
  DatePicker,
  Card,
  Avatar,
  Input,
  Pagination,
  Empty,
  Popconfirm,
} from "antd";
import UserCards from "../../components/UserCard";
import { Images } from "../../config";
import {
  AiFillCaretDown,
  AiFillCalendar,
  AiFillCaretRight,
  AiFillDelete,
  AiOutlineCloseCircle,
  AiOutlineUser,
  AiOutlineSend,
} from "react-icons/ai";

const Messages = () => {
  const { Option } = Select;
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;
  const { TextArea } = Input;
  let width = window.screen.availWidth;

  let [payloadObject, setPayloadObject] = useState({
    startDate: moment().startOf("year").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    status: "",
    limit: 6,
    offset: 0,
  });

  const [messageObj, setMessageObj] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [replyMessage, setReplyMessage] = useState("");
  const [messageId, setMessageId] = useState();

  const headerProps = {
    mainHeading: "Messages",
    subHeading: "",
  };
  const styleBorder = {
    border: "1px solid #55ad3f",
  };

  useEffect(() => {
    dispatch(MessagesActions.getMessage(payloadObject));
  }, []);

  const data = useSelector((state) => {
    return state;
  });

  const messages = {
    allMessages: data?.MessageReducer?.allMessages,
    pendingCount: data?.MessageReducer?.pendingCount,
    repliedCount: data?.MessageReducer?.repliedCount,
    totalCount: data?.MessageReducer?.totalCount,
  };

  const handleChangeGameId = (id) => {
    const payload = {
      ...payloadObject,
      status: id,
    };
    setPayloadObject(payload);
    dispatch(MessagesActions.getMessage(payload));
  };

  const handleSelectDate = (e) => {
    if (e) {
      const payload = {
        ...payloadObject,
        startDate: moment(e[0]).format("YYYY-MM-DD"),
        endDate: moment(e[1]).format("YYYY-MM-DD"),
      };
      setPayloadObject(payload);
      dispatch(MessagesActions.getMessage(payload));
    }
  };

  const handlePageChange = (p) => {
    const limit = 10;
    let offset = (p - 1) * limit;
    setCurrentPage(p);
    const payload = {
      ...payloadObject,
      offset,
    };
    dispatch(MessagesActions.getMessage(payload));
  };

  const showMessage = (e) => {
    setMessageObj(e);
    setMessageId(e?.id);
  };

  const closeMessage = () => {
    setMessageObj({});
    setMessageId("");
  };

  const sendMessage = () => {
    if (!replyMessage)
      return Utils.showSnackBar({
        message: "Message field cannot be left empty.",
        severity: "error",
      });
    const replyObject = {
      ...payloadObject,
      reply: replyMessage,
      id: messageId,
      email: messageObj?.user?.email,
    };
    dispatch(MessagesActions.replyMessage(replyObject));
    setReplyMessage("");
  };

  const deleteMessage = () => {
    const delMessageObj = {
      id: messageId,
      payloadObject,
    };
    dispatch(MessagesActions.deleteMessage(delMessageObj));
  };

  useEffect(() => {
    if (messageId != undefined) {
      let data = messages.allMessages.filter((data) => data.id == messageId);
      setMessageObj(data[0]);
    }
  }, [messages.allMessages]);

  return (
    <Layout.DashboardHeader
      columnSpacing={5}
      customizeStyle={{
        display: "flex",
        padding: 25,
        backgroundColor: "#fffcfc",
        marginLeft: 2,
      }}
    >
      <Header headings={headerProps} />
      <Row justify="space-between" gutter={[10, 10]}>
        <Col xs={24} sm={24} md={24} lg={8}>
          <UserCards
            customeStyle={""}
            mainHeading={messages?.totalCount}
            subHeading={"Total no.of messages"}
            img={Images.MessageOne}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
          <UserCards
            customeStyle={""}
            mainHeading={messages?.repliedCount}
            subHeading={"Total replied messages"}
            img={Images.MessageTwo}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
          <UserCards
            customeStyle={""}
            mainHeading={messages?.pendingCount}
            subHeading={"Pending Messages"}
            img={Images.MessageThree}
          />
        </Col>
      </Row>
      <Row gutter={[10, 10]} style={{ marginTop: "3rem" }}>
        <Col xs={24} sm={24} md={10} lg={10}>
          <Row gutter={[10, 10]}>
            <Col span={24}>
              <Row gutter={[10, 10]}>
                <Col span={14}>
                  <RangePicker
                    suffixIcon={<AiFillCalendar />}
                    style={{ cursor: "pointer", width: "100%" }}
                    size="large"
                    onChange={(e) => handleSelectDate(e)}
                  />
                </Col>
                <Col span={10}>
                  <Select
                    defaultValue="Select Status"
                    size="large"
                    suffixIcon={<AiFillCaretDown />}
                    className="dashboardGame_filter gameFilter"
                    style={{ width: "100%" }}
                    onChange={handleChangeGameId}
                  >
                    <Option value="">Select All</Option>
                    <Option value="0">Pending</Option>
                    <Option value="1">Replied</Option>
                  </Select>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              {(messages.allMessages || []).map((items) => {
                return (
                  <Row align="middle" style={{ marginTop: "1rem" }}>
                    <Col span={23}>
                      <Card
                        className="messageCard"
                        style={items?.id == messageObj?.id ? styleBorder : null}
                        loading={items ? false : true}
                        onClick={() => showMessage(items)}
                      >
                        <Row gutter={[10, 10]}>
                          <Col span={24}>
                            <Row gutter={[10, 10]}>
                              <Col span={3}>
                                <Avatar
                                  icon={
                                    !items?.user?.imageThumbUrl ? (
                                      <AiOutlineUser />
                                    ) : null
                                  }
                                  src={items?.user?.imageThumbUrl}
                                />
                              </Col>
                              <Col span={19}>
                                <Row gutter={[10, 10]}>
                                  <Col span={24}>
                                    <p className="messageName">
                                      {items?.user?.fullName}
                                    </p>
                                  </Col>
                                  <Col span={24}>
                                    <p className="messageDes">
                                      {items?.message}
                                    </p>
                                  </Col>
                                </Row>
                              </Col>
                              <Col span={2}>5h</Col>
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                    {items?.id === messageObj?.id && width > 768 && (
                      <Col span={1} className="card_arrow">
                        <AiFillCaretRight data={messageObj} />
                      </Col>
                    )}
                  </Row>
                );
              })}
            </Col>
            <Col
              span={24}
              style={{ width: "100%", marginTop: "3rem", marginBottom: "3rem" }}
            >
              {/* <Row style={{ width: "100%", marginTop: "3rem" }}>
            <Col span={24}> */}
              {data?.MessageReducer?.allMessages.length ? (
                <Pagination
                  responsive="true"
                  showLessItems="true"
                  defaultCurrent={1}
                  current={currentPage}
                  total={
                    payloadObject.status == 0
                      ? data?.MessageReducer?.pendingCount
                      : data?.MessageReducer?.repliedCount
                  }
                  onChange={(p) => {
                    handlePageChange(p);
                  }}
                />
              ) : (
                <Col>
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={<span>No Messages To Show.</span>}
                  />
                </Col>
              )}
              {/* </Col>
          </Row> */}
            </Col>
          </Row>
        </Col>
        {Object.keys(messageObj || {}).length !== 0 ? (
          <Col xs={24} sm={24} md={14} lg={14}>
            <Card bordered={false} className="replyModal">
              <Row>
                <Col span={24}>
                  <Row>
                    <Col span={21}>
                      <Row>
                        <Col span={24}>
                          <h2>Preview</h2>
                        </Col>
                        <Col span={24}>
                          <p className="messageDate">
                            {moment(messageObj?.updatedAt).calendar(null, {
                              sameDay: "[Today]",
                              nextDay: "[Tomorrow]",
                              nextWeek: "dddd",
                              lastDay: "[Yesterday]",
                              lastWeek: "[Last] dddd",
                              sameElse: "DD-MMM-YYYY",
                            })}{" "}
                            ,{" "}
                            {moment(messageObj?.updatedAt).format(
                              "MMMM Do YYYY h:mm A"
                            )}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={3}>
                      <Row>
                        <Col span={12}>
                          <Popconfirm
                            title="Are you sure to delete this message?"
                            onConfirm={() => deleteMessage()}
                            // onCancel={}
                            okText="Yes"
                            cancelText="No"
                          >
                            <AiFillDelete className="iconStyles iconDel" />
                          </Popconfirm>
                        </Col>
                        <Col span={12}>
                          <AiOutlineCloseCircle
                            className="iconStyles"
                            onClick={() => closeMessage()}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Row gutter={[10, 10]}>
                    <Col>
                      <Avatar
                        icon={
                          !messageObj?.user?.imageThumbUrl ? (
                            <AiOutlineUser />
                          ) : null
                        }
                        src={messageObj?.user?.imageThumbUrl}
                        shape="square"
                      />
                    </Col>
                    <Col xs={21} sm={22} md={22} lg={22} span={22}>
                      <Row>
                        <Col span={24}>
                          <p className="messageName">
                            {messageObj?.user?.fullName}
                          </p>
                        </Col>
                        <Col span={24}>
                          <p>{messageObj?.user?.email}</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <p>{messageObj?.message}</p>
                </Col>
                {!!messageObj?.messageReplies?.length ? (
                  <Col span={24}>
                    <Row gutter={[10, 10]}>
                      <Col span={24}>
                        <Row gutter={[10, 10]}>
                          <Col xs={3} sm={2} md={2} lg={2} span={2}>
                            <Avatar
                              icon={<AiOutlineUser />}
                              // src={messageObj?.user?.imageThumbUrl}
                              shape="square"
                            />
                          </Col>
                          <Col xs={21} sm={22} md={22} lg={22} span={22}>
                            <Row>
                              <Col span={24}>
                                <p className="messageName">Admin</p>
                              </Col>
                              <Col span={24}>{/* <p>{}</p> */}</Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={24}>
                        {(messageObj?.messageReplies || []).map((item) => {
                          return (
                            <Col className="adminReply">
                              <p className="replyMsg">{item.reply}</p>
                              <p className="replyDate">
                                {moment(item.createdAt).format("MMMM Do YYYY ")}
                              </p>
                            </Col>
                          );
                        })}
                      </Col>
                    </Row>
                  </Col>
                ) : (
                  ""
                )}
                <Col span={24} className="replyInput">
                  <TextArea
                    rows={4}
                    value={replyMessage}
                    placeholder="write your message here..."
                    maxLength={250}
                    onChange={(e) => setReplyMessage(e.target.value)}
                  />
                </Col>
                <Col
                  span={24}
                  style={{
                    marginTop: "1rem",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <button
                    type="submit"
                    className="replyButton"
                    onClick={() => sendMessage()}
                  >
                    Send
                    <AiOutlineSend />
                  </button>
                </Col>
              </Row>
            </Card>
          </Col>
        ) : (
          <Col xs={24} sm={24} md={14} lg={14}>
            <Empty
              description={<span>Click chat box to view message.</span>}
              style={{ marginTop: "50%" }}
            />
          </Col>
        )}
      </Row>
    </Layout.DashboardHeader>
  );
};

export default Messages;
