// Dev base URL
// export const API_BASE_URL =
//   "https://ppprofit.5stardesigners.net:3001/dev/play_pound_profit/api/v1";

export const API_BASE_URL =
  // "https://api.playpoundprofit.co.uk:3001/dev/play_pound_profit/api/v1";
  "https://api.playpoundprofit.co.uk:3002/dev/play_pound_profit/api/v1";

// "homepage": "https://admin.playpoundprofit.co.uk/",

export const CHANGEPASSWORD_ENDPOINT = "/update-password";

// ----------------------- PPP --------------------------------

//AUTH ENDPOINTS
export const LOGIN_ENDPOINT = "/auth/login";
export const FORGETPASSWORD_ENDPOINT = "/auth/forget-password";
export const VERIFYCODE_ENDPOINT = "/auth/verify-token";
export const RESETPASSWORD_ENDPOINT = "/auth/reset-password";
export const LOGOUT_ENDPOINT = "/user/logout";
export const GAMES_ENDPOINT = "/admin/games";
export const WINNER_ENDPOINT = "/admin/winners";
export const WINNER_UPDATE_ENDPOINT = "/admin/winner";
export const GETGAMES_ENDPOINT = "/game";
export const SENDNOTIFICATION_ENDPOINT = "/admin/send-notification";
export const GETUSERS_ENDPOINT = "/admin/users";
export const USERSTATUSUPDATE_ENDPOINT = "/admin/users";
export const USER_STATS_ENDPOINT = "/admin/user-stats";
export const FREEPAID_GAMES = "/admin/free-paid-game-stats";
export const TOTALGAMES = "/admin/total-game-stats";
export const SIGNUPSTATS = "/admin/signup-stats";
export const MESSAGES_ENDPOINT = "/admin/messages";
export const TRANSACTION_ENDPOINT = "/admin/transactions";
export const PAY_IN_DETAILS_ENDPOINT = "/admin/transactions/payin";
export const PAY_OUT_DETAILS_ENDPOINT = "/admin/transactions/payout";

const configVariables = {
  // appUrl,
  // appUrlForReview,
  //baseUrl: `${baseUrl}`,
  termsUrl: "",
  privacyUrl: "",
  contactSupportMail: "",
  //instructionsUrl: `${baseUrl}`,
};

const constantVariables = {
  monthNames: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  messageForAppShare: "",
  subjectForAppShare: "",
};

export default {
  ...configVariables,
  ...constantVariables,
};
