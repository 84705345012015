import React, { useEffect, useState } from "react";
import { Layout, Charts } from "../../components";
import Header from "../../components/Layout/Header";
import UserCards from "../../components/UserCard";
import { useDispatch, useSelector } from "react-redux";
import { DashboardAction, GamesActions } from "../../store/actions";
import { Row, Col, Select, DatePicker } from "antd";
import { Images } from "../../config";
import "./index.css";
import moment from "moment";
import { AiFillCaretDown, AiFillCalendar } from "react-icons/ai";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";

const Dashboard = () => {
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  const [gameId, setGameid] = useState("");
  const [startDate, setStartDate] = useState(
    moment()?.startOf("year").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));

  const [totalGamesStartDate, setTotalGamesStartDate] = useState(
    moment()?.startOf("year").format("YYYY-MM-DD")
  );
  const [totalGamesEndDate, setTotalGamesEndDate] = useState(
    moment()?.format("YYYY-MM-DD")
  );

  const [signupStartDate, setSignupStartDate] = useState(
    moment()?.startOf("year").format("YYYY-MM-DD")
  );
  const [signupEndDate, setSignupEndDate] = useState(
    moment()?.format("YYYY-MM-DD")
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const getGames = {
      id: gameId,
      endDate,
      startDate,
    };
    const getTotalGames = {
      startDate: totalGamesStartDate,
      endDate: totalGamesEndDate,
    };
    const getPlateformUsers = {
      startDate: signupStartDate,
      endDate: signupEndDate,
    };
    dispatch(DashboardAction.userStats());
    dispatch(DashboardAction.getFreeAndPaidGames(getGames));
    dispatch(DashboardAction.getTotalGamesPlayed(getTotalGames));
    dispatch(DashboardAction.getSignupStats(getPlateformUsers));
    dispatch(GamesActions.GetFilterGames());
  }, []);

  const data = useSelector((state) => {
    return state;
  });

  const headerProps = {
    mainHeading: "Welcome to Dashboard",
    subHeading: "",
  };

  // const { totalPaid, totalPayout, totalUsers } = data?.DashboardReducer?.userStats;
  let totalPaid = data?.DashboardReducer?.userStats?.totalPaid;
  let totalPayout = data?.DashboardReducer?.userStats?.totalPayout;
  let totalUsers = data?.DashboardReducer?.userStats?.totalUsers;
  let freePaidGameArr = data?.DashboardReducer?.freePaidGames;
  let totalGamePlayed = data?.DashboardReducer?.totalGamesPlayedGraph;
  let signupStatsGraph = data?.DashboardReducer?.signupStats;

  const handleChangeGameId = (id) => {
    const getGames = {
      id,
      startDate,
      endDate,
    };
    dispatch(DashboardAction.getFreeAndPaidGames(getGames));
  };

  let games = [{ name: "View All", id: "" }, ...data?.GamesReducer?.allGames];

  const selectDate = (e) => {
    if (!e) {
      const getGames = {
        id: gameId,
        startDate: moment()?.startOf("year").format("YYYY-MM-DD"),
        endDate: moment()?.format("YYYY-MM-DD"),
      };
      dispatch(DashboardAction.getFreeAndPaidGames(getGames));
      setStartDate(moment()?.startOf("year").format("YYYY-MM-DD"));
      setEndDate(moment()?.format("YYYY-MM-DD"));
      return;
    }
    const getGames = {
      id: gameId,
      startDate: moment(e[0])?.format("YYYY-MM-DD"),
      endDate: moment(e[1])?.format("YYYY-MM-DD"),
    };
    dispatch(DashboardAction.getFreeAndPaidGames(getGames));
    setStartDate(moment(e[0])?.format("YYYY-MM-DD"));
    setEndDate(moment(e[1])?.format("YYYY-MM-DD"));
  };

  const changeTotalGamesDate = (e) => {
    if (!e) {
      const totalGamesObj = {
        startDate: moment()?.startOf("year").format("YYYY-MM-DD"),
        endDate: moment()?.format("YYYY-MM-DD"),
      };
      dispatch(DashboardAction.getTotalGamesPlayed(totalGamesObj));
      setTotalGamesStartDate(moment()?.startOf("year").format("YYYY-MM-DD"));
      setTotalGamesEndDate(moment()?.format("YYYY-MM-DD"));
      return;
    }
    const totalGamesObj = {
      startDate: moment(e[0])?.format("YYYY-MM-DD"),
      endDate: moment(e[1])?.format("YYYY-MM-DD"),
    };
    dispatch(DashboardAction.getTotalGamesPlayed(totalGamesObj));
    setTotalGamesStartDate(moment(e[0])?.format("YYYY-MM-DD"));
    setTotalGamesEndDate(moment(e[1])?.format("YYYY-MM-DD"));
  };

  const getPlatformUsers = (e) => {
    if (!e) {
      const platformUsers = {
        startDate: moment()?.startOf("year").format("YYYY-MM-DD"),
        endDate: moment()?.format("YYYY-MM-DD"),
      };
      dispatch(DashboardAction.getSignupStats(platformUsers));
      setSignupStartDate(moment()?.startOf("year").format("YYYY-MM-DD"));
      setSignupEndDate(moment()?.format("YYYY-MM-DD"));
      return;
    }
    const platformUsers = {
      startDate: moment(e[0])?.format("YYYY-MM-DD"),
      endDate: moment(e[1])?.format("YYYY-MM-DD"),
    };
    dispatch(DashboardAction.getSignupStats(platformUsers));
    setSignupStartDate(moment(e[0])?.format("YYYY-MM-DD"));
    setSignupEndDate(moment(e[1])?.format("YYYY-MM-DD"));
  };

  return (
    <Layout.DashboardHeader
      // click={this.handleClose}
      columnSpacing={5}
      customizeStyle={{
        display: "flex",
        padding: 25,
        backgroundColor: "#fffcfc",
        marginLeft: 2,
      }}
    >
      <Header headings={headerProps} />
      <Row justify="space-between" gutter={[10, 10]}>
        <Col xs={24} sm={24} md={24} lg={8}>
          <UserCards
            customeStyle={"cardOne"}
            mainHeading={totalUsers}
            subHeading={"Total Users"}
            img={Images.DashboardOne}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
          <UserCards
            customeStyle={"cardTwo"}
            mainHeading={totalPaid}
            subHeading={"Total Paid Users"}
            img={Images.DashboardTwo}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={8}>
          <UserCards
            customeStyle={"cardThree"}
            mainHeading={`$${totalPayout}`}
            subHeading={"Total payout this month"}
            img={Images.DashboardThree}
          />
        </Col>
      </Row>
      <br />
      <Row gutter={[10, 10]} justify="space-between">
        <Col xs={24} sm={24} md={24} lg={12}>
          <Row gutter={[10, 10]}>
            <Col>
              <h2 style={{ fontWeight: "bold" }}>Free vs Paid Games</h2>
            </Col>
            <Col className="chartsDiv">
              <Row gutter={[10, 10]}>
                <Col span={24}>
                  <Row gutter={[10, 10]}>
                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={10}
                      className="dashboardGame_filter"
                    >
                      {/* <Row className="rowGameFilter"> */}
                      <Select
                        defaultValue="Select Game"
                        suffixIcon={<AiFillCaretDown />}
                        className="dashboardGame_filter"
                        size="large"
                        style={{
                          width: "100%",
                        }}
                        onChange={handleChangeGameId}
                      >
                        {(games || []).map((item) => {
                          return (
                            <>
                              <Option value={item.id}>{item.name}</Option>
                            </>
                          );
                        })}
                      </Select>
                      {/* </Row> */}
                    </Col>

                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={14}
                      className="dashboardGame_filter"
                    >
                      {/* <Row className="rowGameFilter"> */}
                      <RangePicker
                        suffixIcon={<AiFillCalendar />}
                        style={{ cursor: "pointer", width: "100%" }}
                        size="large"
                        onChange={(e) => selectDate(e)}
                      />
                      {/* </Row> */}
                    </Col>
                  </Row>
                </Col>

                <Col span={24}>
                  <Charts.ResponsiveContainer
                    style={{ margin: "27px 0px" }}
                    height={350}
                  >
                    {(freePaidGameArr || [])
                      .map((val) => val.free || val.paid)
                      .reduce((total, value) => total + value, 0) > 0 ? (
                      <Charts.LineChart
                        data={freePaidGameArr}
                        // lineColor={["#4BE5C0", "#4E5FF8"]}
                        loading={false}
                        margin={{ top: 10, right: 50, left: 0, bottom: 0 }}
                      />
                    ) : (
                      <h4
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      ></h4>
                    )}
                  </Charts.ResponsiveContainer>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col xs={24} sm={24} md={24} lg={11}>
          <Row gutter={[10, 10]}>
            <Col>
              <h2 style={{ fontWeight: "bold" }}>Total Games Played</h2>
            </Col>
            <Col className="chartsDiv">
              <Row gutter={[10, 10]}>
                <Col
                  span={24}
                  className="dashboardGame_filter"
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <RangePicker
                    suffixIcon={<AiFillCalendar />}
                    style={{ cursor: "pointer" }}
                    size="large"
                    onChange={(e) => changeTotalGamesDate(e)}
                  />
                </Col>

                <Col span={24}>
                  <Charts.ResponsiveContainer
                    style={{ margin: "27px 0px" }}
                    height={350}
                  >
                    {(totalGamePlayed || [])
                      .map((val) => val.totalGames)
                      .reduce((total, value) => total + value, 0) > 0 ? (
                      <Charts.LineChart
                        data={totalGamePlayed}
                        // lineColor={["#4BE5C0", "#4E5FF8"]}
                        loading={false}
                        margin={{ top: 10, right: 50, left: 0, bottom: 0 }}
                      />
                    ) : (
                      <h4
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      ></h4>
                    )}
                  </Charts.ResponsiveContainer>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row style={{ marginTop: "2rem" }}>
        <Col span={24}>
          <h2 style={{ fontWeight: "bold" }}>Signups</h2>
          <p>Number of signups (android and IOS)</p>
        </Col>
        <Col span={24} className="chartsDiv">
          <Row gutter={[10, 10]}>
            <Col xs={24} sm={24} md={7} lg={7}>
              <Row justify="space-between" align="middle">
                {/* <Col></Col>
                <Col></Col> */}
                <div class="box yellow"></div>Android User<br></br>
                <div class="box green"></div>IOS User<br></br>
              </Row>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={17}
              className="dashboardGame_filter"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <RangePicker
                suffixIcon={<AiFillCalendar />}
                style={{ cursor: "pointer" }}
                size="large"
                onChange={(e) => getPlatformUsers(e)}
              />
            </Col>
            <Col span={24}>
              <Charts.ResponsiveContainer
                style={{ margin: "27px 0px" }}
                height={350}
              >
                <BarChart
                  width={500}
                  height={300}
                  data={signupStatsGraph}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  {/* <Legend /> */}
                  <Bar dataKey="apple" fill="#55AD3F" />
                  <Bar dataKey="google" fill="#F2CC2D" />
                </BarChart>
              </Charts.ResponsiveContainer>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout.DashboardHeader>
  );
};
export default Dashboard;
