import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Utils } from "../../config";
import { AuthAction } from "../../store/actions";
import { Images, Colors } from "../../config";
import {
  RiMailFill,
  RiEyeFill,
  RiEyeCloseLine,
  RiCheckboxCircleFill,
} from "react-icons/ri";
import { AiFillLock } from "react-icons/ai";
import { Row, Col, Input } from "antd";

const SignIn = () => {
  const [showPass, setShowPass] = useState(false);
  const [password, setPassword] = useState("");
  const [passErrMes, setPassErrMes] = useState("");

  const [email, setEmail] = useState("");
  const [emailErrMes, setEmailErrMes] = useState("");

  const [validEmail, setValidEmail] = useState(true);
  const [validPass, setValidPass] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();

  const signin = () => {
    if (!email || !validEmail || !password || !validPass) {
      if (!email) {
        setEmailErrMes("Required fields can not be left empty.");
        setValidEmail(false);
      }
      if (!validEmail) {
        setEmailErrMes("Please enter a valid email address.");
      }
      if (!password) {
        setPassErrMes("Required fields can not be left empty.");
        setValidPass(false);
      }
      if (!validPass) {
        setPassErrMes(
          "Password length must be atleast 8 char and must include an upper case and lower case char"
        );
      }
    } else {
      const objLogin = {
        email,
        password,
      };
      dispatch(AuthAction.SignIn(objLogin, () => history.push("/")));
    }
    //  else
    //     SignIn({ current_time, email, password }, () => history.push('/'))
  };

  const validateEmail = (email) => {
    let validEmail = Utils.emailRegex.test(String(email).toLowerCase());
    setEmail(email);
    setValidEmail(validEmail);
    setEmailErrMes("");
  };

  const validatePass = (password) => {
    // let validPass = password.length > 5;
    let validPass = Utils.passwordRegex.test(String(password));
    setPassword(password);
    setValidPass(validPass);
    setPassErrMes("");
  };
  return (
    <Row justify="center">
      <Col className="imgColumn" xs={0} sm={0} md={12} lg={12}>
        <Row justify="center" style={{ padding: "3rem 0rem" }}>
          <Col span={18}>
            <Row justify="center">
              <Col>
                <h1 className="headingOne">Get Better at Gaming!</h1>
              </Col>
              <Col>
                <h1 className="regularHead">Dedicate Time to Playing Games.</h1>
              </Col>
              <Col>
                <img src={Images.SignInPhone} className="Box1_PhoneImg" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>

      <Col span={12} className="formColumn" xs={24} sm={24} md={12} lg={12}>
        <Row justify="center">
          <Col span={18}>
            <Row justify="left" style={{ padding: "3rem 0rem" }}>
              <Col>
                <img src={Images.Logo} />
              </Col>

              <Col>
                <h1 className="headingOne">Log in to Play Pound Profit.</h1>
              </Col>

              <Col>
                <h2 className="regularHead">
                  Welcome back! login with your Email Address..
                </h2>
              </Col>

              <Col span={24}>
                <Row>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      signin();
                    }}
                    style={{ width: "100%" }}
                  >
                    <Col className="colEmail" span={24}>
                      <div>
                        <p className="label">Email Address</p>
                      </div>
                      <Input
                        type={"email"}
                        value={email}
                        placeholder={"Email"}
                        className="inputStyles"
                        onChange={(e) => validateEmail(e.target.value)}
                        prefix={<RiMailFill style={{ color: "#009743" }} />}
                        suffix={
                          <RiCheckboxCircleFill
                            style={{ color: email.length ? "#009743" : "red" }}
                          />
                        }
                        customStyle={{
                          backgroundColor: "white",
                          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: Colors.Border,
                            },
                          "& .MuiOutlinedInput-root:hover": {
                            "& > fieldset": {
                              borderColor: Colors.Primary,
                            },
                          },
                        }}
                      />
                      <span style={{ color: Colors.Danger }}>
                        {" "}
                        {emailErrMes}{" "}
                      </span>
                    </Col>

                    <Col className="colPass" span={24}>
                      <div>
                        <p className="label">Password</p>
                      </div>
                      <Input
                        type={!showPass ? "password" : "text"}
                        placeholder="Password"
                        name="password"
                        value={password}
                        className="inputStyles"
                        prefix={<AiFillLock />}
                        suffix={
                          !showPass ? (
                            <RiEyeCloseLine
                              style={{ cursor: "pointer" }}
                              onClick={() => setShowPass(!showPass)}
                            />
                          ) : (
                            <RiEyeFill
                              style={{ cursor: "pointer" }}
                              onClick={() => setShowPass(!showPass)}
                            />
                          )
                        }
                        onChange={(e) => validatePass(e.target.value)}
                      />
                      <span style={{ color: Colors.Danger }}>
                        {" "}
                        {passErrMes}{" "}
                      </span>
                    </Col>

                    <Col span={24} style={{ marginTop: "2rem" }}>
                      <button
                        type="submit"
                        className="btn btnLogin"
                        size="large"
                      >
                        LOGIN
                      </button>
                    </Col>
                    <Col span={24} style={{ marginTop: "1rem" }}>
                      <p
                        className="txt_forgotPass"
                        onClick={() => history.push("/forget-password")}
                      >
                        Forgot your password?
                      </p>
                    </Col>
                  </form>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SignIn;
