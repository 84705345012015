import { CLOSE_MARK_PAID_MODAL, GET_WINNERS, GET_WINNERS_SUCCESS, MARK_WINNER_PAID, MARK_WINNER_PAID_FAILURE, MARK_WINNER_PAID_SUCCESS, OPEN_MARK_PAID_MODAL } from "../constants";

const initialState = {
  isMarkPaidOpen: false,
  isLoading: false,
  winnersData: [],
  winnerCount: 0,
};
export default function UserReducer(state = initialState, action) {
  switch (action.type) {
    case GET_WINNERS:
      state = {
        ...state,
        isLoading: true,
      };

      break;

    case GET_WINNERS_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        winnerCount: action?.payload?.count,
        winnersData: action?.payload?.winners,
      };
      break;

    case OPEN_MARK_PAID_MODAL:
      state = {
        ...state,
        isMarkPaidOpen: true,
      };
      break;

    case CLOSE_MARK_PAID_MODAL:
      state = {
        ...state,
        isMarkPaidOpen: false,
      };
      break;

    case MARK_WINNER_PAID:
      state = {
        ...state,
        isLoading: true
      }
      break;

    case MARK_WINNER_PAID_SUCCESS:
      state = {
        ...state,
        isLoading: false,
        isMarkPaidOpen: false
      }
      break;

    case MARK_WINNER_PAID_FAILURE:
      state = {
        ...state,
        isLoading: false,
      }
      break;

    default:
      break;
  }
  return state;
}
