import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Router } from "react-router-dom";
import { Utils } from "./config"
import { Provider } from "react-redux"
import Store from "./store"
import 'antd/dist/antd.css';

ReactDOM.render(
  <Router history={Utils.history}>
    <Provider store={Store}>

      <App />
    </Provider>
  </Router>,
  document.getElementById('root')
);

reportWebVitals();
