import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Layout } from "../../components";
import Header from "../../components/Layout/Header";
import WinnerDetailsModal from './molecules/WinnerDetailsModal'
import {
  WinnersAction,
  GamesActions,
  NotificationAction,
} from "../../store/actions";
import { Modal } from "../../components";
import {
  Row,
  Col,
  Tabs,
  Table,
  Avatar,
  Select,
  Tag,
  Pagination,
  Input,
} from "antd";
import { FaCheckDouble, FaClock, FaEye, FaRegQuestionCircle, FaSearch } from "react-icons/fa";
import { IoIosSend } from "react-icons/io"
import moment from "moment";
import { Actions } from "../../components/atoms/Actions";
import { Images } from "../../config";

const { TabPane } = Tabs;
const { Option } = Select;

const WinnerManagement = () => {
  const [tabName, setTabName] = useState("done");
  const [currentRecord, setCurrentRecord] = useState()
  // const [filterBy, setFilterBy] = useState('view all')
  const [gameId, setGameId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [ModalVisible, setIsModalVisible] = useState(false);
  const [isDelete, setIsDelete] = useState(true);
  const [isDate, setIsDate] = useState("");
  const [isGame, setIsGame] = useState("");
  const [isProfile, setIsprofile] = useState("");
  const [userId, setIsUserId] = useState("");
  const [userPlayedGameId, setUserPlayedGames] = useState("");
  // const [columns, setColumns] = useState(tblPayDone);

  const dispatch = useDispatch();

  const openMarkPaid = (record) => {
    setCurrentRecord(record)
    dispatch(WinnersAction.OpenMarkPaidModal())

  }

  const sendNotification = (record) => {
    dispatch(NotificationAction.SendNotification(record))
  }

  const renderActions = {
    'pending': (text, record) => {
      return (
        <Actions>
          {record?.isRequestedForMoney ? (
            <Actions.Button sx={{
              bgcolor: "#2ED6A31A",
              color: "#54AC3F",
              '&:hover': { bgcolor: "#2ED6A31A" },
              cursor: "auto"
            }}
              icon={FaClock}
            />
          ) : (
            <>
              <Actions.Button
                icon={IoIosSend}
                title="Send Notification"
                sx={{ bgcolor: "#f4f0e0", color: "#f2cc2d", '&:hover': { bgcolor: "#f4f0e0" } }}
                onClick={() => {
                  setIsGame(record?.gameTier?.game?.name);
                  setIsprofile(record?.user?.fullName);
                  setIsDate(moment(record?.createdAt).format("DD-MM-YY"));
                  setIsUserId(record?.user?.id);
                  setUserPlayedGames(record?.userPlayedGameId);
                  setIsModalVisible(true);
                }}
              // onClick={() => sendNotification(record)}
              />
            </>
          )}
          {/* <Actions.Button title="Send Notification" sx={{ bgcolor: "#2ED6A31A", color: "#54AC3F" }} icon={FaClock} onClick={() => sendNotification(record)} /> */}
          <Actions.Button sx={{ bgcolor: "#d9dcea" }} icon={FaEye} title="View More Details" onClick={() => openMarkPaid(record)} />
        </Actions>
      )
    },
    'missed': (_text, record) => {
      return (
        <Actions>
          <Actions.Button title="Send Notification" sx={{ bgcolor: "#f4f0e0", color: "#f2cc2d" }} icon={IoIosSend} onClick={() => sendNotification(record)} />
        </Actions>
      )
    },
    'done': (_text, record) => {
      return (
        <div>
          {record?.user?.stripeAccount?.accountId ? (
            <Tag color="green">
              <FaCheckDouble style={{ marginRight: "5px" }} />
              Connected
            </Tag>
          ) : (
            <Tag color="red">
              <FaRegQuestionCircle style={{ marginRight: "5px" }} />
              Not Connected
            </Tag>
          )}
        </div>
      )
    }
  }

  useEffect(() => {
    const objGetData = {
      limit: 10,
      offSet: 0,
      status: tabName,
      gameId,
      search,
    };
    dispatch(WinnersAction.GetWinners(objGetData));
    dispatch(GamesActions.GetFilterGames());
    // eslint-disable-next-line
  }, []);

  const data = useSelector((state) => {
    return state;
  });

  console.log({ datas: data })

  const { winnerCount } = data.WinnerReducer;

  const headerProps = {
    mainHeading:
      tabName == "done"
        ? `Winner Management (${winnerCount ? winnerCount : ""})`
        : "Winner Management",
    subHeading: `List of winners - Payment ${tabName}`,
  };

  function callback(key) {
    setTabName(key);
    setSearch("");
    const objTabNameFilter = {
      limit: 10,
      offSet: 0,
      status: key,
      gameId,
    };
    dispatch(WinnersAction.GetWinners(objTabNameFilter));
  }

  const handleOk = () => {
    const notifyObj = {
      gameName: isGame,
      userId: userId,
      userPlayedGameId: userPlayedGameId,
    };
    dispatch(NotificationAction.SendNotification(notifyObj));
    setIsGame("");
    setIsUserId("");
    setUserPlayedGames("");
    setIsprofile("");
    setIsDate("");
    setUserPlayedGames("");
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsGame("");
    setIsUserId("");
    setUserPlayedGames("");
    setIsprofile("");
    setIsDate("");
    setUserPlayedGames("");
    setIsModalVisible(false);
  };

  const handleChangeGameId = (id) => {
    setGameId(id);

    const filterObj = {
      limit: 10,
      offSet: 0,
      status: tabName,
      gameId: id,
      search,
    };
    dispatch(WinnersAction.GetWinners(filterObj));
    setCurrentPage(1);
  };

  const tblPayDone = [
    {
      title: "",
      dataIndex: "imageThumbUrl",
      key: "image",
      render: (text, record) => {
        return <Avatar src={record?.user?.imageThumbUrl} shape="square" />;
      },
    },
    {
      title: "User Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return <div>{record?.user?.fullName}</div>;
      },
    },
    {
      title: "Email",
      dataIndex: "userEmail",
      key: "email",
      render: (text, record) => {
        return (
          <div>
            {record?.user?.deletedAt == null ? record?.user?.email : "-"}
          </div>
        );
      },
    },
    {
      title: "Winning Date",
      dataIndex: "createdAt",
      key: "winningDate",
      render: (text, record) => {
        return <div>{moment(record?.createdAt).format("DD-MM-YYYY")}</div>;
      },
    },
    {
      title: "Redeem",
      dataIndex: "paidGamesPlayed",
      key: "redeem",
      render: (text, record) => {
        return <div>{record?.redeemStatus}</div>;
      },
    },
    {
      title: "Game",
      dataIndex: "totalAmountPaid",
      key: "game",
      render: (text, record) => {
        return <div>{record?.gameTier?.game?.name}</div>;
      },
    },

    tabName == "done"
      ? {
        title: "Disbursement Date",
        dataIndex: "paidGamesPlayed",
        key: "Disbursement Date",
        hidden: true,
        render: (text, record) => {
          return (
            tabName == "done" && (
              <div>{moment(record?.disbursementDate).format("DD-MM-YY")}</div>
            )
          );
        },
      }
      : {},

    {
      title: "Payment Status",
      dataIndex: "paidGamesPlayed",
      key: "status",
      hidden: true,
      render: (text, record) => {
        return (
          <div>
            {record?.isRequestedForMoney ? (
              <Tag color="green">
                <FaCheckDouble style={{ marginRight: "5px" }} />
                Real Money
              </Tag>
            ) : (
              <Tag color="red">
                <FaRegQuestionCircle style={{ marginRight: "5px" }} />
                On Wait
              </Tag>
            )}
          </div>
        );
      },
    },
    // tabName == "done"
    //   ? {}
    //   : {
    //     title: "Action",
    //     dataIndex: "paidGamesPlayed",
    //     key: "action",
    //     hidden: true,
    //     render: (text, record) => {
    //       return (
    //         <div>
    //           {record?.isRequestedForMoney ? (
    //             <img alt="" src={Images.Waiting} />
    //           ) : (
    //             <img
    //               alt=""
    //               src={Images.Check}
    //               style={{ cursor: "pointer" }}
    //               onClick={() => {
    //                 setIsGame(record?.gameTier?.game?.name);
    //                 setIsprofile(record?.user?.fullName);
    //                 setIsDate(moment(record?.createdAt).format("DD-MM-YY"));
    //                 setIsUserId(record?.user?.id);
    //                 setUserPlayedGames(record?.userPlayedGameId);
    //                 setIsModalVisible(true);
    //               }}
    //             // onClick={() => sendNotification(record)}
    //             />
    //           )}
    //         </div>
    //       );
    //     },
    //   },
    {
      title: "Requested Amount",
      dataIndex: "money",
      key: "amount",
      hidden: true,
      render: (text, record) => {
        return (
          <div>
            £{record.winningAmount}
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "paidGamesPlayed",
      key: "action",
      hidden: true,
      render: renderActions[tabName],
    },
  ];

  const [columns, setColumns] = useState(tblPayDone);
  let games = [
    { name: "View All", value: "" },
    ...data?.GamesReducer?.allGames,
  ];


  useEffect(() => {
    const hiddenCols = {
      "done": ["Disbursement Date", "Payment Status", "Requested Amount", "Action"],
      "pending": ["Redeem"],
      "missed": []
    }
    setColumns(tblPayDone.filter(col => !hiddenCols[tabName].includes(col.title)));
  }, [tabName]);

  const handle_payDone = (p) => {
    const limit = 10;
    let offSet = (p - 1) * limit;
    setCurrentPage(p);
    const objPaginationData = {
      search,
      gameId,
      limit,
      offSet,
      status: tabName,
    };
    dispatch(WinnersAction.GetWinners(objPaginationData));

    // dispatch(WinnersAction.GetWinner(filterObj))
  };

  useEffect(() => {
    const searchObj = {
      search,
      gameId,
      limit: 10,
      offSet: 0,
      status: tabName,
    };
    dispatch(WinnersAction.GetWinners(searchObj));
  }, [search]);

  return (
    <Layout.DashboardHeader
      // click={this.handleClose}
      columnSpacing={5}
      customizeStyle={{
        display: "flex",
        padding: 25,
        backgroundColor: "#fffcfc",
        marginLeft: 2,
      }}
    >
      <Header headings={headerProps} />
      <Row>
        <Col xs={24} sm={24} md={24} lg={10}>
          <Input
            type="text"
            placeholder="Search with Name and Email"
            className="searchBar"
            size="large"
            value={search}
            suffix={<FaSearch style={{ color: "#54ac3e" }} />}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </Col>
        <Col span={24}>
          <Tabs
            className="winnersTabs"
            defaultActiveKey="1"
            onChange={callback}
          >
            <TabPane tab="Payment Done" key="done">
              <Row className="tbl_game">
                <Col span={24}>
                  <Table
                    dataSource={data?.WinnerReducer?.winnersData}
                    columns={columns}
                    pagination={false}
                  />
                </Col>
              </Row>
              <Row style={{ width: "100%", marginTop: "3rem" }}>
                <Col span={24}>
                  <Pagination
                    responsive="true"
                    showLessItems="true"
                    defaultCurrent={1}
                    current={currentPage}
                    total={winnerCount ? winnerCount : 0}
                    onChange={(p) => {
                      handle_payDone(p);
                    }}
                  />
                </Col>
              </Row>
            </TabPane>

            <TabPane tab="Payment Pending" key="pending">
              <WinnerDetailsModal record={currentRecord} />
              <Row className="tbl_game">
                <Col span={24}>
                  <Table
                    dataSource={data?.WinnerReducer?.winnersData}
                    columns={columns}
                    pagination={false}
                  />
                </Col>
              </Row>
              <Row style={{ width: "100%", marginTop: "3rem" }}>
                <Col span={24}>
                  <Pagination
                    responsive="true"
                    showLessItems="true"
                    defaultCurrent={1}
                    // current={reportedUser_meta?.current_page}
                    total={winnerCount ? winnerCount : 0}
                    onChange={(p) => {
                      handle_payDone(p);
                    }}
                  />
                </Col>
              </Row>
            </TabPane>
            {/* 
            <TabPane tab="Payment Missed" key="missed">
              <Row className="tbl_game">
                <Col span={24}>
                  <Table
                    dataSource={data?.WinnerReducer?.winnersData}
                    columns={columns}
                    pagination={false}
                  />
                </Col>
              </Row>
              <Row style={{ width: "100%", marginTop: "3rem" }}>
                <Col span={24}>
                  <Pagination
                    responsive="true"
                    showLessItems="true"
                    defaultCurrent={1}
                    // current={reportedUser_meta?.current_page}
                    total={winnerCount ? winnerCount : 0}
                    onChange={(p) => {
                      handle_payDone(p);
                    }}
                  />
                </Col>
              </Row>
            </TabPane> */}
          </Tabs>
          <Row className="rowGameFilter">
            <Select
              defaultValue="Select Game"
              className="gameFilter"
              size="large"
              onChange={handleChangeGameId}
            >
              {(games || []).map((item) => {
                return (
                  <>
                    <Option value={item.id}>{item.name}</Option>
                  </>
                );
              })}
            </Select>
          </Row>
        </Col>
        {/* <Col>

                </Col> */}
      </Row>
      <Modal.ModalComponent
        isDelete={isDelete}
        isModalVisible={ModalVisible}
        handleOk={handleOk}
        //   isReporters={this.state.isRepoter}

        handleCancel={handleCancel}
        centered
        closable={false}
        //  supportID={supportID}
        footer={null}
        content={
          <>
            <Row>
              <Col span={24}>
                <p style={{ fontSize: 16 }}>
                  Following email and notification will be sent to the user{" "}
                </p>
              </Col>
              <Col span={24}>
                <p style={{ fontSize: 16 }}>
                  "Reminder !! Please redeem your winning amount as real money
                  or coins from{" "}
                  <span style={{ fontWeight: "bold" }}>{isProfile} </span> for{" "}
                  <span style={{ fontWeight: "bold" }}>{isGame} </span> you
                  played on{" "}
                  <span style={{ fontWeight: "bold" }}>{isDate}"</span>
                </p>
              </Col>
              <Col span={24}>
                <p style={{ fontSize: 16 }}>
                  {" "}
                  {`Are you sure you want to do this?`}
                </p>
              </Col>
            </Row>
          </>
        }
      />
    </Layout.DashboardHeader>
  );
};
export default WinnerManagement;
