import { ApiCaller } from "../../config";
import { Utils } from "../../config";
import { SENDNOTIFICATION_ENDPOINT } from "../../config/variables";

export default class NotificationMiddleware {
  static *Send_Notification(payload) {
    const { gameName, userId, userPlayedGameId } = payload.payload;
    const objNotify = {
      gameName,
      userId,
      userPlayedGameId,
    };
    let res = yield ApiCaller.Post(SENDNOTIFICATION_ENDPOINT, objNotify);
    if (res.status == 200) {
      Utils.showSnackBar({ message: res.data.data, severity: "success" });
    }
  }
}
