export const RESETPASSWORD = "RESETPASSWORD";
export const RESETPASSWORD_SUCCESS = "RESETPASSWORD_SUCCESS";
export const RESETPASSWORD_FAILURE = "RESETPASSWORD_FAILURE";

export const VERIFYCODE = "VERIFYCODE";
export const VERIFYCODE_SUCCESS = "VERIFYCODE_SUCCESS";
export const VERIFYCODE_FAILURE = "VERIFYCODE_FAILURE";

export const GETUSERSCARDCOUNT = "GETUSERSCARDCOUNT";
export const GETUSERSCARDCOUNT_SUCCESS = "GETUSERSCARDCOUNT_SUCCESS";
export const GETUSERSCARDCOUNT_FAILURE = "GETUSERSCARDCOUNT_FAILURE";

export const UPDATEUSERSTATUS = "UPDATEUSERSTATUS";
export const UPDATEUSERSTATUS_SUCCESS = "UPDATEUSERSTATUS_SUCCESS";
export const UPDATEUSERSTATUS_FAILURE = "UPDATEUSERSTATUS_FAILURE";

export const ADD_POST = "ADD_POST";
export const ADD_POST_SUCCESS = "ADD_POST_SUCCESS";
export const ADD_POST_FAILURE = "ADD_POST_FAILURE";

export const GET_POSTS = "GET_POSTS";
export const GET_POSTS_SUCCESS = "GET_POSTS_SUCCESS";
export const GET_POSTS_FAILURE = "GET_POSTS_FAILURE";

export const GETUSERS = "GETUSERS";
export const GETUSERS_SUCCESS = "GETUSERS_SUCCESS";
export const GETUSERS_FAILURE = "GETUSERS_FAILURE";

export const USER_GRAPH = "USER_GRAPH";
export const USER_GRAPH_SUCCESS = "USER_GRAPH_SUCCESS";
export const USER_GRAPH_ERROR = "USER_GRAPH_ERROR";

export const ANDROID_GRAPH = "ANDROID_GRAPH";
export const ANDROID_GRAPH_SUCCESS = "ANDROID_GRAPH_SUCCESS";
export const ANDROID_GRAPH_ERROR = "ANDROID_GRAPH_ERROR";

export const LOADER_TRUE = "LOADER_TRUE";
export const LOADER_FALSE = "LOADER_FALSE";

export const TRAVELPLAN = "TRAVELPLAN";
export const TRAVELPLAN_SUCCESS = "TRAVELPLAN_SUCCESS";
export const TRAVELPLAN_FAILURE = "TRAVELPLAN_FAILURE";

export const BUYERPLAN = "BUYERPLAN";
export const BUYERPLAN_SUCCESS = "BUYERPLAN_SUCCESS";
export const BUYERPLAN_FAILURE = " BUYERPLAN_FAILURE";

// -------------------- PPP --------------------------

export const SIGNIN = "SIGNIN";
export const SIGNIN_SUCCESS = "SIGNIN_SUCCESS";
export const SIGNIN_FAILURE = "SIGNIN_FAILURE";

export const FORGETPASSWORD = "FORGETPASSWORD";
export const FORGETPASSWORD_SUCCESS = "FORGETPASSWORD_SUCCESS";
export const FORGETPASSWORD_FAILURE = "FORGETPASSWORD_FAILURE";

export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const GET_GAMES = "GET_GAMES";
export const GET_GAMES_SUCCESS = "GET_GAMES_SUCCESS";
export const GET_GAMES_FAILURE = "GET_GAMES_FAILURE";

export const GETFILTERGAMES = "GETFILTERGAMES";
export const GETFILTERGAMES_SUCCESS = "GETFILTERGAMES_SUCCESS";
export const GETFILTERGAMES_FAILURE = "GETFILTERGAMES_FAILURE";

export const GETWINNERS_FILTER = "GETWINNERS_FILTER";
export const GETWINNERS_FILTER_SUCCESS = "GETWINNERS_FILTER_SUCCESS";
export const GETWINNERS_FILTER_FAILURE = "GETWINNERS_FILTER_FAILURE";

export const GET_WINNERS = "GET_WINNERS";
export const GET_WINNERS_SUCCESS = "GET_WINNERS_SUCCESS";
export const GET_WINNERS_FAILURE = "GET_WINNERS_FAILURE";

export const OPEN_MARK_PAID_MODAL = "OPEN_MARK_PAID_MODAL";
export const CLOSE_MARK_PAID_MODAL = "CLOSE_MARK_PAID_MODAL";
export const MARK_WINNER_PAID = "MARK_WINNER_PAID"
export const MARK_WINNER_PAID_SUCCESS = "MARK_WINNER_PAID_SUCCESS"
export const MARK_WINNER_PAID_FAILURE = "MARK_WINNER_PAID_FAILURE"

export const GETWINNERS_PAY_DONE = "GETWINNERS_PAY_DONE";
export const GETWINNERS_PAY_DONE_SUCCESS = "GETWINNERS_PAY_DONE_SUCCESS";
export const GETWINNERS_PAY_DONE_FAILURE = "GETWINNERS_PAY_DONE_FAILURE";

export const GETWINNERS_PAY_PENDING = "GETWINNERS_PAY_PENDING";
export const GETWINNERS_PAY_PENDING_SUCCESS = "GETWINNERS_PAY_PENDING_SUCCESS";
export const GETWINNERS_PAY_PENDING_FAILURE = "GETWINNERS_PAY_PENDING_FAILURE";

export const GETWINNERS_PAY_MISSED = "GETWINNERS_PAY_MISSED";
export const GETWINNERS_PAY_MISSED_SUCCESS = "GETWINNERS_PAY_MISSED_SUCCESS";
export const GETWINNERS_PAY_MISSED_FAILURE = "GETWINNERS_PAY_MISSED_FAILURE";

export const SEND_NOTIFICATION = "SEND_NOTIFICATION";
export const SEND_NOTIFICATION_SUCCESS = "SEND_NOTIFICATION_SUCCESS";
export const SEND_NOTIFICATION_FAILURE = "SEND_NOTIFICATION_FAILURE";

export const USER_STATS = "USER_STATS";
export const USER_STATS_SUCCESS = "USER_STATS_SUCCESS";
export const USER_STATS_FAILURE = "USER_STATS_FAILURE";

export const FREE_PAID_GAMES = "FREE_PAID_GAMES";
export const FREE_PAID_GAMES_SUCCESS = "FREE_PAID_GAMES_SUCCESS";
export const FREE_PAID_GAMES_FAILURE = "FREE_PAID_GAMES_FAILURE";

export const TOTAL_GAMES = "TOTAL_GAMES";
export const TOTAL_GAMES_SUCCESS = "TOTAL_GAMES_SUCCESS";
export const TOTAL_GAMES_FAILURE = "TOTAL_GAMES_FAILURE";

export const SIGNUP_STATS = "SIGNUP_STATS";
export const SIGNUP_STATS_SUCCESS = "SIGNUP_STATS_SUCCESS";
export const SIGNUP_STATS_FAILURE = "SIGNUP_STATS_FAILURE";

export const MESSAGE = "MESSAGE";
export const MESSAGE_SUCCESS = "MESSAGE_SUCCESS";
export const MESSAGE_FAILURE = "MESSAGE_FAILURE";

export const REPLY_MESSAGE = "REPLY_MESSAGE";
export const REPLY_MESSAGE_SUCCESS = "REPLY_MESSAGE_SUCCESS";
export const REPLY_MESSAGE_FAILURE = "REPLY_MESSAGE_FAILURE";

export const DELETE_MESSAGE = "DELETE_MESSAGE";
export const DELETE_MESSAGE_SUCCESS = "DELETE_MESSAGE_SUCCESS";
export const DELETE_MESSAGE_FAILURE = "DELETE_MESSAGE_FAILURE";

export const TRANSACTION = "TRANSACTION";
export const TRANSACTION_SUCCESS = "TRANSACTION_SUCCESS";
export const TRANSACTION_FAILURE = "TRANSACTION_FAILURE";

export const PAY_IN_DETAILS_REQUEST = "PAY_IN_DETAILS_REQUEST";
export const PAY_IN_DETAILS_SUCCESS = "PAY_IN_DETAILS_SUCCESS";
export const PAY_IN_DETAILS_FAILURE = "PAY_IN_DETAILS_FAILURE";

export const PAY_OUT_DETAILS_REQUEST = "PAY_OUT_DETAILS_REQUEST";
export const PAY_OUT_DETAILS_SUCCESS = "PAY_OUT_DETAILS_SUCCESS";
export const PAY_OUT_DETAILS_FAILURE = "PAY_OUT_DETAILS_FAILURE";
