import React from 'react';
import { DatePicker, Row, Col } from "antd";
import {Colors} from "../../config"
import { RiCalendarLine } from 'react-icons/ri';
import styled from "styled-components";
import moment from "moment";
const { RangePicker } = DatePicker;
const monthFormat = 'MMM YYYY';
const MonthPicker = styled(RangePicker)`
  color: ${Colors.Primary};
  width: 282px;
  border: 2px solid ${Colors.Primary};
  border-radius: 15px;
  border-color: ${Colors.Primary};
  cursor: pointer;
  height: 3.5rem;
  background-color: #1C2B3F;
  & .ant-picker-input > input {
    color: white;
  }
  & .ant-picker:hover {
    border: 2px solid ${Colors.Primary};
  }
  & .ant-picker-header {
    background-color: #1C2B3F;
  }
`;

const MonthRangePicker = (props) => {
  return <MonthPicker  {...props}>
</MonthPicker>;
}

export default MonthRangePicker
