import {
  SIGNIN,
  SIGNIN_SUCCESS,
  SIGNIN_FAILURE,
  LOGOUT,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  FORGETPASSWORD,
  FORGETPASSWORD_SUCCESS,
  FORGETPASSWORD_FAILURE,
  RESETPASSWORD,
  RESETPASSWORD_SUCCESS,
  RESETPASSWORD_FAILURE,
  VERIFYCODE,
  VERIFYCODE_SUCCESS,
  VERIFYCODE_FAILURE,
} from "../constants";

export default class AuthAction {
  static SignIn(payload, cb) {
    return {
      type: SIGNIN,
      payload,
      cb,
    };
  }
  static SignInSuccess(payload) {
    return {
      type: SIGNIN_SUCCESS,
      payload,
    };
  }
  static SignInFailure() {
    return {
      type: SIGNIN_FAILURE,
    };
  }

  static Logout() {
    return {
      type: LOGOUT,
    };
  }
  static LogoutSuccess() {
    return {
      type: LOGOUT_SUCCESS,
    };
  }
  static LogoutFailure() {
    return {
      type: LOGOUT_FAILURE,
    };
  }

  static ForgetPassword(email, cb) {
    return {
      type: FORGETPASSWORD,
      email,
      cb,
    };
  }
  static ForgetPasswordSuccess(payload) {
    return {
      type: FORGETPASSWORD_SUCCESS,
      payload,
    };
  }
  static ForgetPasswordFailure() {
    return {
      type: FORGETPASSWORD_FAILURE,
    };
  }
  static ResetPassword(payload, cb) {
    return {
      type: RESETPASSWORD,
      payload,
      cb,
    };
  }
  static ResetPasswordSuccess(payload) {
    return {
      type: RESETPASSWORD_SUCCESS,
      payload,
    };
  }
  static ResetPasswordFailure() {
    return {
      type: RESETPASSWORD_FAILURE,
    };
  }

  static VerifyCode(payload, cb) {
    return {
      type: VERIFYCODE,
      payload,
      cb,
    };
  }
  static VerifyCodeSuccess(payload) {
    return {
      type: VERIFYCODE_SUCCESS,
      payload,
    };
  }
  static VerifyCodeFailure() {
    return {
      type: VERIFYCODE_FAILURE,
    };
  }
}
