import { AuthAction, UserAction } from "../actions";
import { ApiCaller } from "../../config";
import { put, delay } from "redux-saga/effects";
import { Utils } from "../../config";
import {
  USERSTATUSUPDATE_ENDPOINT,
  GETUSERS_ENDPOINT,
} from "../../config/variables";
export default class AppMiddleware {
  static *GetUsers(params) {
    const { limit, offSet, search } = params.payload;

    try {
      let res = yield ApiCaller.Get(
        `${GETUSERS_ENDPOINT}?limit=${limit}&offset=${offSet}&search=${
          search ? search : ""
        }`
      );
      if (res.status == 200) {
        const userObj = {
          count: res.data.data.count,
          users: res.data.data.users,
        };
        yield put(UserAction.GetUsersSuccess(userObj));
      } else {
        yield put(UserAction.GetUsersFailure());
        Utils.showSnackBar({
          message: res.data.error.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(UserAction.GetUsersFailure());
    }
  }

  static *UpdateUserStatus(params) {
    const { id, status, limit, offSet } = params?.payload;
    try {
      let res = yield ApiCaller.Put(`${USERSTATUSUPDATE_ENDPOINT}/${id}`, {
        status,
      });
      if (res.status == 200) {
        Utils.showSnackBar({ message: res.data.data });
        yield put(UserAction.UpdateUserStatusSuccess());
        yield put(UserAction.GetUsers({ limit, offSet }));
      } else {
        yield put(UserAction.UpdateUserStatusFailure());
        //   Info("error", res.data.error.message);
        Utils.showSnackBar({
          message: res.data.error.message,
          severity: "error",
        });
      }
    } catch (err) {
      yield put(UserAction.UpdateUserStatusFailure());
    }
  }
}
