import { IconButton, Stack, Tooltip } from "@mui/material"

export const Actions = ({ children }) => {
    return <Stack direction="row" spacing={1} alignItems="center">
        {children}
    </Stack>
}

export const ActionButton = ({ icon: Icon, title, sx, ...rest }) => {

    if (!title) return <IconButton size="large" sx={{ borderRadius: '5px', ...sx }} {...rest}>
        <Icon size={16} />
    </IconButton>

    return <Tooltip title={title}>
        <IconButton size="large" sx={{ borderRadius: '5px', ...sx }} {...rest}>
            <Icon size={16} />
        </IconButton>
    </Tooltip>
}

Actions.Button = ActionButton

