const Colors = {
    PlaceHolder: (opacity = "0.5") => `rgba(235, 235, 235, ${opacity})`,
    BlackOpacity: (opacity = "0.5") => `rgba(0, 0, 0, ${opacity})`,
    WhiteOpacity: (opacity = "0.5") => `rgba(255, 255, 255, ${opacity})`,
    Black:"#000000",
    Transparent: "transparent",
    Primary: "#F28787",
    Secondary: "#F3CB7E",
    DarkGray: "#1E1E22",
    White: "#ffffff",
    Text: "#ffffff",
    Danger: "#FF494C",
    Dark:"#1E2D4A"
}

export default Colors;