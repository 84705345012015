import React, { useEffect, useState } from 'react'
import { Layout } from "../../components";
import { GamesActions } from "../../store/actions";
import Header from '../../components/Layout/Header'
import { useDispatch, useSelector } from "react-redux";
import { Table, Avatar } from 'antd'

const Games = () => {
    // const [gameData, setGameData] = useState([])

    const dispatch = useDispatch()

    const data = useSelector((store) => {
        return store.GamesReducer
    })

    useEffect(() => {
        dispatch(GamesActions.GetGames())
    }, [])

    // useEffect(() => {
    //     setGameData(data.GamesReducer.gameData)
    // }, [data.GamesReducer])

    const headerProps = {
        mainHeading: 'Games',
        subHeading: ''
    }

    const columns = [
        {
            title: '',
            dataIndex: 'imageThumbUrl',
            key: 'image',
            width: '1%',
            render: (i, b) => <div>{<Avatar src={b?.imageThumbUrl} />}</div>
        },
        {
            title: 'Game Name',
            dataIndex: 'name',
            key: 'name',
            width: '2%',
        },
        {
            title: 'Req. users to announce winner',
            dataIndex: 'reqUsersToAnnWinn',
            key: 'usersTo',
            width: '5%',


        },
        {
            title: 'No. of free games played',
            dataIndex: 'freeGamesPlayed',
            key: 'freeGames',
            width: '5%',
        },
        {
            title: 'No. of paid games played',
            dataIndex: 'paidGamesPlayed',
            key: 'paidGames',
            width: '5%',
        },
        {
            title: 'Total amount paid',
            dataIndex: 'totalAmountPaid',
            key: 'amountPaid',
            width: '5%',
        },
        {
            title: 'Number of users in current prize pool',
            dataIndex: 'usersInCurrPrizePool',
            key: 'currentPool',
            width: '5%',
        },
    ];

    return (
        <Layout.DashboardHeader
            // click={this.handleClose}
            columnSpacing={5}
            customizeStyle={{
                display: "flex",
                padding: 25,
                backgroundColor: "#fffcfc",
                marginLeft: 2,
            }}
        >
            <Header headings={headerProps} />
            <div className='tbl_game'>
                <Table dataSource={data?.gameData} columns={columns} pagination={false} />
            </div>
        </Layout.DashboardHeader>
    )

}
export default Games