import { GETUSERS, GETUSERS_SUCCESS, GETUSERS_FAILURE } from "../constants";
const initialState = {
  isLoading: false,
  users: [],
  userCount: "",
};

export default function UserReducer(state = initialState, action) {
  switch (action.type) {
    case GETUSERS:
      state = {
        ...state,
        isLoading: true,
      };
      break;
    case GETUSERS_SUCCESS: {
      const { users, count } = action.payload;
      state = {
        ...state,
        userCount: count,
        users: users,
        isLoading: false,
      };
      break;
    }

    case GETUSERS_FAILURE:
      state = {
        ...state,
        isLoading: false,
      };
      break;

    default:
      break;
  }

  return state;
}
