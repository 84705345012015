import React from "react";
import Grid from "@mui/material/Grid";
import { Images } from "../../config";
import { Link } from "react-router-dom";
const Footer = ({ ...props }) => {
  const pathName = window.location.pathname;
  const isUser = pathName.includes("users");
  const isOrder = pathName.includes("orders");
  // const isDashboard = !isUser && !isOrder;

  // ==============================================
  const isMessage = pathName.includes("messages")
  const isWinnerManagement = pathName.includes("winner");
  const isGames = pathName.includes("games")
  const isUserManagement = pathName.includes("user-management")
  const isTransaction = pathName.includes("transaction")

  const isDashboard = !isWinnerManagement && !isMessage && !isGames && !isUserManagement && !isTransaction;
  return (
    <Grid container item xs={12} sm={12} md={12} lg={12}>
      <Grid
        container
        columnSpacing={2}
        style={{ marginTop: "16px", justifyContent: "center" }}
      >
        <Grid item md={12} lg={12}>
          <Link to="/">
            <img src={isDashboard ? Images.DashboardClicked : Images.Dashboard} style={{ cursor: "pointer", color: 'red' }}></img>
          </Link>
        </Grid>

        <Grid item md={12} lg={12}>
          <Link to="/users">
            <img src={isUserManagement ? Images.UserClciked : Images.User} style={{ cursor: "pointer" }}></img>
          </Link>
        </Grid>

        <Grid item md={12} lg={12}>
          <Link to="/games">
            <img src={isGames ? Images.GameClicked : Images.Game} style={{ cursor: "pointer" }}></img>
          </Link>
        </Grid>

        <Grid item md={12} lg={12}>
          <Link to="/winner-management">
            <img src={isWinnerManagement ? Images.WinnerClicked : Images.Winner} style={{ cursor: "pointer" }}></img>
          </Link>
        </Grid>

        <Grid item md={12} lg={12}>
          <Link to="/messages">
            <img src={isMessage ? Images.MessageClicked : Images.Message} style={{ cursor: "pointer" }}></img>
          </Link>
        </Grid>
        <Grid item md={12} lg={12}>
          <Link to="/transaction">
            <img src={isTransaction ? Images.TransactionsClicked : Images.Transactions} style={{ cursor: "pointer" }}></img>
          </Link>
        </Grid>

      </Grid>
    </Grid>)
};

export default Footer;
